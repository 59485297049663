import { NgModule } from '@angular/core';

import {  MatToolbarModule} from '@angular/material/toolbar';
import {  MatIconModule} from '@angular/material/icon';
import {  MatMenuModule} from '@angular/material/menu';

import { DragDropModule } from '@angular/cdk/drag-drop';

import {MatGridListModule} from '@angular/material/grid-list';
import {MatListModule} from '@angular/material/list';
import {MatDialogModule} from '@angular/material/dialog'
import {MatFormFieldModule} from '@angular/material/form-field'
import {BrowserAnimationsModule}  from '@angular/platform-browser/animations'
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox';


//needed by MatIcon
import { HttpClientModule } from "@angular/common/http";

@NgModule({
  imports: [
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    DragDropModule,
    MatGridListModule,
    MatListModule,
    HttpClientModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MatCheckboxModule

  ],
  exports: [
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    DragDropModule,
    MatGridListModule,
    MatListModule,
    HttpClientModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MatCheckboxModule
  ]
})
export class MaterialModule { }
