import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SchematService } from 'src/app/service/schemat.service';

import { Schemat, Entity,  SchematChange, Connection, ConnectionType, Cardinality, EntityViewChange, ChangeAction } from 'src/app/schemat';

import { getEntityById, getEntityByIdIncludeImports, isLocal, getLinkedConnectionsSearchImports } from 'src/app/schemat-read-util';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-entity-form',
  templateUrl: './entity-form.component.html',
  styleUrls: ['./entity-form.component.css']
})
export class EntityFormComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router,
    private schematService: SchematService,
    private formBuilder: FormBuilder
    ) { 
  }

  id: String
  entityId: String

  schemat : Schemat
  entity: Entity

  formGroup: FormGroup;
  titleAlert: string = 'This field is required';
  editable : boolean

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.entityId = params['entityId'];
      this.schemat = this.schematService.getById(this.id) 
      this.entity = getEntityByIdIncludeImports(this.schemat, this.entityId)
      this.editable = isLocal(this.schemat, this.entityId)
    });
    this.createForm();
    this.setChangeValidate()
  }

connections() : Connection[] {
    return   getLinkedConnectionsSearchImports(this.schemat, this.entity)
}

  connectionsOut() : Connection[] {
       return this.schemat.connections.filter(c => c.entity1==this.entity)
  }

  connectionsIn() : Connection[] {
    return this.schemat.connections.filter(c => c.entity2==this.entity)
}

  setChangeValidate() {
   /* this.formGroup.get('validate').valueChanges.subscribe(
      (validate) => {
        if (validate == '1') {
          this.formGroup.get('name').setValidators([Validators.required, Validators.minLength(3)]);
          this.titleAlert = "You need to specify at least 3 characters";
        } else {
          this.formGroup.get('name').setValidators(Validators.required);
        }
        this.formGroup.get('name').updateValueAndValidity();
      }const
    )*/
  }

  createForm() {
   // let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.formGroup = this.formBuilder.group({
     // 'email': [null, [Validators.required, Validators.pattern(emailregex)], this.checkInUseEmail],
      'name': [this.entity.name, Validators.required],
      /*'password': [null, [Validators.required, this.checkPassword]],
      'description': [null, [Validators.required, Validators.minLength(5), Validators.maxLength(10)]],
      'validate': ''*/
    });
  }

  get name() {
    return this.formGroup.get('name') as FormControl
  }

  apply() {
    const newname = this.formGroup.value['name']
    if (this.entity.name != newname) {
        const change: SchematChange = { id: this.id, _a:ChangeAction.None,
         entities: [{id: this.entityId, _a:ChangeAction.Update, name:newname }]  } 
        this.schematService.change(change) 
    }
  }

  deletable() : boolean 
     {
       return this.connections().length==0 && this.editable
     }

  delete() {
     const entityViewChanges : EntityViewChange[] = this.schemat.entityViews.filter((ev)=> {return ev.entity === this.entity }).map(
       (ev) => { return { id: ev.id, _a:ChangeAction.Delete, _del:  true   }   });
     this.schematService.change({id: this.schemat.id, _a:ChangeAction.None, entityViews: entityViewChanges  }); 
     this.schematService.change({id: this.schemat.id, _a:ChangeAction.None, entities: [{id: this.entity.id, _a:ChangeAction.Delete  }]  }); 
     this.router.navigate(['all' ])
     //this.router.navigate(['editor', this.id ])
  }

  clickConnection(connection: Connection) {
    this.router.navigate(['connection', this.id, connection.id ])  
  }

  back() {
    this.router.navigate(['editor', this.id ])
  }

  save() {
    this.apply()
    this.back() 
  }

  cancel() {
     this.back()
  }

  newProperty() {
    //TODO get rid of this hard coded id !
    const connectionChange =  {id: null, _a:ChangeAction.Insert, name:"newproperty", entity1: this.entityId, entity2: "String",
    connectionType: ConnectionType.Contains, cardinality: Cardinality.OneToOne } 
    const change: SchematChange = {id: this.id, _a:ChangeAction.None, connections: [ connectionChange ]}
    this.schematService.change(change)

    this.router.navigate(['connection', this.id, connectionChange.id ])  

    //const postfix = "" + this.entity.properties.length
    //addProperty(this.entity, new Property(null, "name" + postfix, false, "String", null))
  }


}
