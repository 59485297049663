import * as uuid from 'uuid';

import {
  Schemat, Change, SchematChange,
  Entity, EntityChange, Connection, Cardinality, ConnectionType, ConnectionChange, EntityView, EntityViewChange, ChangeAction, Role, RoleChange, RoleView, RoleViewChange, ViewPosition, PathChange, Path, PathViewChange, PathView, PathSegment, PathSegmentChange, Permissions
} from './schemat'
import { RectangleBounds, Point } from './schemat-externals'
import {
  getEntityById, getEntityViewById, getConnectionById, findEntityImportById, getRoleById,
  getRoleViewById, getPathViewById, getPathById, getConnectionByIdSearchimports, getAllPathSegments, getEntityByIdIncludeImports
} from './schemat-read-util'

export const refField = "__ref"

type Dictionary = { [index: string]: object }

export function newid(): String {
  return uuid.v4()
}

function applyEntityChange(entity: Entity, change: EntityChange) {
  entity.id = change.id
  if (change.name != null) entity.name = change.name
  if (change.isExternal != null) entity.isExternal = change.isExternal
  if (change.isValueType != null) entity.isValueType = change.isValueType
  if (change.kotlinName != null) entity.kotlinName = change.kotlinName
  // properties
}

function applyRoleChange(role: Role, change: RoleChange) {
  role.id = change.id
  if (change.name != null) role.name = change.name
  //TODO redo this
  //if (change.paths != null) entity.paths = change.paths
}

const dummyEntity = new Entity("dummy", "dummy", true)
const dummyConnection = new Connection("dummy", "dummy", dummyEntity, dummyEntity,
  Cardinality.OneToOne, ConnectionType.Contains)

function applyPathSegmentChanges(schemat: Schemat, existingPathSegments: PathSegment[], pathSegmentChanges: PathSegmentChange[])
  : PathSegment[] {
  pathSegmentChanges.forEach(
    (segmentChange) => {

      if (!existingPathSegments) {
        existingPathSegments = []
      }
      if (ChangeAction.Insert == segmentChange._a) {

        const newSegment = new PathSegment(null as unknown as String, dummyConnection, false, undefined, [])
        applyPathSegmentChangeWithContext(schemat, newSegment, segmentChange)
        existingPathSegments.push(newSegment)
      } else if (ChangeAction.Update == segmentChange._a || ChangeAction.None == segmentChange._a) {
        const existingSegments = existingPathSegments.filter((s) => { return segmentChange.id == s.id })
        if (existingSegments.length == 0) {
          throw Error(`cant update not existing segment segmentChange ${segmentChange.id} from ${segmentChange}`)
        }
        const existingSegment = existingSegments[0]
        applyPathSegmentChangeWithContext(schemat, existingSegment, segmentChange)
      } else if (ChangeAction.Delete == segmentChange._a) {
        existingPathSegments = existingPathSegments.filter((s) => { return s.id != segmentChange.id })
      }
      else throw new Error(`cant process PathSegment changeAction: ${segmentChange._a}`)

    }
  )
  return existingPathSegments
}


function applyPathChange(schemat: Schemat, path: Path, change: PathChange) {
  path.id = change.id
  if (change.name != null) path.name = change.name
  if (typeof (change.entityDefault) !== 'undefined') path.entityDefault = change.entityDefault
  if (change.permissions) {
    const p = path.permissions
    const pChange = change.permissions
    if (!p) {
      path.permissions = change.permissions
    } else {
      //TODO what to do about id ?
      if (typeof (pChange.deny) !== 'undefined') p.deny = pChange.deny
      if (typeof (pChange.read) !== 'undefined') p.read = pChange.read
      if (typeof (pChange.write) !== 'undefined') p.write = pChange.write
    }
  }

  if (change.segments != null) {
    path.segments = applyPathSegmentChanges(schemat, path.segments, change.segments)
  }
  //TODO redo this
  //if (change.paths != null) entity.paths = change.paths
}


function applyEntityViewChange(entityView: EntityView, change: EntityViewChange) {
  entityView.id = change.id
  if (change.x != null) entityView.x = change.x
  if (change.y != null) entityView.y = change.y
  if (change.width != null) entityView.width = change.width
  if (change.height != null) entityView.height = change.height
}

function applyRoleViewChange(roleView: RoleView, change: RoleViewChange) {
  roleView.id = change.id
  if (change.position != null) {
    const pChange = change.position
    const p = roleView.position
    if (pChange.x != null) p.x = pChange.x
    if (pChange.y != null) p.y = pChange.y
    if (pChange.width != null) p.width = pChange.width
    if (pChange.height != null) p.height = pChange.height
  }
  //TODO fix this
  //if (change.role != null) roleView.role = change.role
}

function applyPathViewChange(pathView: PathView, change: PathViewChange) {
  pathView.id = change.id
  if (change.position != null) {
    const pChange = change.position
    const p = pathView.position
    if (pChange.x != null) p.x = pChange.x
    if (pChange.y != null) p.y = pChange.y
    if (pChange.width != null) p.width = pChange.width
    if (pChange.height != null) p.height = pChange.height
  }
}

export function defaultSchemat(id: String) {
  return new Schemat(id, "name", new RectangleBounds(0, 0, 200, 200),
    100, 100, new Point(10, 10), [], [], [])
}

function applyConnectionChange(connection: Connection, connectionChange: ConnectionChange) {
  if (connectionChange.cardinality != null) connection.cardinality = connectionChange.cardinality
  if (connectionChange.connectionType != null) connection.connectionType = connectionChange.connectionType
  if (connectionChange.name != null) connection.name = connectionChange.name
  if (connectionChange.minTo != null) connection.minTo = connectionChange.minTo
}

function applyPathSegmentChange(schemat: Schemat, pathSegment: PathSegment, pathSegmentChange: PathSegmentChange) {
  if (pathSegmentChange.id) pathSegment.id = pathSegmentChange.id;
  if (typeof (pathSegmentChange.allProperties) !== 'undefined') pathSegment.allProperties = pathSegmentChange.allProperties;
  if (pathSegmentChange.permissions) {
    const pChange = pathSegmentChange.permissions
    var p = pathSegment.permissions
    if (!p) {
      pathSegment.permissions = new Permissions(pChange.id)
      p = pathSegment.permissions
    }
    if (typeof (pChange.deny) !== 'undefined') p.deny = pChange.deny
    if (typeof (pChange.read) !== 'undefined') p.read = pChange.read
    if (typeof (pChange.write) !== 'undefined') p.write = pChange.write
  }

  if (pathSegmentChange.segments != null) {
    pathSegment.segments = applyPathSegmentChanges(schemat, pathSegment.segments, pathSegmentChange.segments)
  }

}

function applyEntityChangeWithContext(schemat: Schemat, ce: EntityChange) {
  if (ChangeAction.Delete == ce._a) {
    if (!ce.id) {
      throw new Error("entity delete has no id");
    }
    var entity = getEntityById(schemat, ce.id)
    if (entity == null) {
      console.log("cant find entity to delete " + ce.id);
    }
    schemat.entities = schemat.entities.filter((c) => { return ce.id != c.id });
    return;
  }

  if (!(ce.id)) {
    ce.id = newid()
  }
  var targetEntity = getEntityById(schemat, ce.id)
  var importedEntity = findEntityImportById(schemat, ce.id);
  if (targetEntity == null && importedEntity == null) {
    targetEntity = new Entity(ce.id, "default", false)
    schemat.entities.push(targetEntity)
  }
  var importedEntity = findEntityImportById(schemat, ce.id);
  if (targetEntity != null && importedEntity == null) { applyEntityChange(targetEntity, ce) }
}

function applyPathSegmentChangeWithContext(schemat: Schemat, pathSegment: PathSegment, pathSegmentChange: PathSegmentChange) {
  if (pathSegmentChange.connection) {
    var connection = getConnectionByIdSearchimports(schemat, pathSegmentChange.connection)
    if (connection == null) {
      console.log(`cant find connection in path segment: ${pathSegmentChange.connection}`, schemat)
      //throw new Error(`cant find connection in path segment: ${pathSegmentChange.connection}`)
      const dummyEntity = new Entity(importedEntityPlaceholderName, importedEntityPlaceholderName, false)
      connection = new Connection(pathSegmentChange.connection, importedEntityPlaceholderName,
        dummyEntity, dummyEntity, Cardinality.OneToOne, ConnectionType.Contains)
    }
    pathSegment.connection = connection
  }
  applyPathSegmentChange(schemat, pathSegment, pathSegmentChange)
}

function applyRoleChangeWithContext(schemat: Schemat, rc: RoleChange) {
  if (ChangeAction.Delete == rc._a) {
    if (!rc.id) {
      throw new Error("role delete has no id");
    }
    var entity = getRoleById(schemat, rc.id)
    if (entity == null) {
      throw new Error("cant find role to delete " + rc.id);
    }
    schemat.roles = schemat.roles.filter((c) => { return rc.id != c.id });
    return;
  }

  if (!(rc.id)) {
    rc.id = newid()
  }
  var targetRole = getRoleById(schemat, rc.id)
  if (targetRole == null) {
    targetRole = new Role(rc.id, "default", [])
    schemat.roles.push(targetRole)
  }
  if (rc.paths!=null) {
    const newPaths = []
    for (var i =0 ; i<rc.paths.length; i++) {
      const path = getPathById(schemat, rc.paths[i])
      if (!path) {
        console.log(`cant find path ${rc.paths[i]} in role ${targetRole.name}:${targetRole.id}`, schemat)
        throw new Error(`cant find path ${rc.paths[i]} in role ${targetRole.name}:${targetRole.id}`)
      }
      newPaths.push(path)
    }
    targetRole.paths = newPaths
  }
  if (targetRole != null) { applyRoleChange(targetRole, rc) }
}

function applyPathChangeWithContext(schemat: Schemat, rc: PathChange) {
  if (ChangeAction.Delete == rc._a) {
    if (!rc.id) {
      throw new Error("path delete has no id");
    }
    var path = getPathById(schemat, rc.id)
    if (path == null) {
      //TODO should this be an error ?
      console.log("****Waning**********cant find path to delete " + rc.id);
    }
    schemat.paths = schemat.paths.filter((c) => { return rc.id != c.id });
    return;
  }

  if (!(rc.id)) {
    rc.id = newid()
  }
  var target = getPathById(schemat, rc.id)
  if (target == null) {
    target = new Path(rc.id, "default", dummyEntity, false, undefined, [])
    schemat.paths.push(target)
  }
  applyPathChange(schemat, target, rc)
  if (rc.root) {
    var root = getEntityByIdIncludeImports(schemat, rc.root)
    if (!root) {
      console.log("invalid entity view entity hope its an import !", rc.root, schemat);
      //throw new Error("invalid entity view entity " + change.entity);
      root = new Entity(rc.root, importedEntityPlaceholderName, true, undefined, undefined, undefined, true);
    }
    target.root = root;
  }
}

function applyConnectionChangeWithContext(schemat: Schemat, ce: ConnectionChange) {
  if (ChangeAction.Delete == ce._a) {
    if (!ce.id) {
      throw new Error("connection delete has no id");
    }
    var connection = getConnectionById(schemat, ce.id)
    if (connection == null) {
      throw new Error("cant find connection to delete " + ce.id);
    }
    schemat.connections = schemat.connections.filter((c) => { return ce.id != c.id });
    return;
  }

  var entity1 = getEntityById(schemat, ce.entity1 as String)
  if (entity1 == null) {
    entity1 = findEntityImportById(schemat, ce.entity1 as String);
  }
  var entity2 = getEntityById(schemat, ce.entity2 as String)
  if (entity2 == null) {
    entity2 = findEntityImportById(schemat, ce.entity2 as String);
  }

  if (ce.entity1 != null && entity1 == null) {
    console.log("invalid connection entity1, assuming import ", ce.entity1, schemat);
    //throw new Error("invalid connection entity1 " + ce.entity1)
    entity1 = new Entity(ce.entity1, importedEntityPlaceholderName, true, undefined, undefined, undefined, true);
  }
  if (ce.entity2 != null && entity2 == null) {
    console.log("invalid connection entity2, assuming import ", ce.entity2, schemat);
    //throw new Error("invalid connection entity1 " + ce.entity1)
    entity2 = new Entity(ce.entity2, importedEntityPlaceholderName, true, undefined, undefined, undefined, true);
  }

  if (ce.id == null) ce.id = newid()
  var connection = getConnectionById(schemat, ce.id)
  if (connection == null) {
    if (entity1 == null || entity2 == null) {
      throw new Error("invalid connection entity1 and 2 must be specified" + ce.entity2)
    }
    connection = new Connection(ce.id, ce.name as String, entity1, entity2, ce.cardinality as Cardinality, ce.connectionType as ConnectionType)
    schemat.connections.push(connection)
  }
  applyConnectionChange(connection, ce)
  if (entity1 != null) connection.entity1 = entity1
  if (entity2 != null) connection.entity2 = entity2

}

export const importedEntityPlaceholderName = "_import"

function applyEntityViewChangeWithContext(schemat: Schemat, change: EntityViewChange) {
  if (ChangeAction.Delete == change._a) {
    if (!change.id) {
      throw new Error("entity view delete has no id");
    }
    var entityView = getEntityViewById(schemat, change.id)
    if (entityView == null) {
      throw new Error("cant find entity view to delete " + change.id);
    }
    schemat.entityViews = schemat.entityViews.filter((c) => { return change.id != c.id });
    return;
  }

  var entity = getEntityById(schemat, change.entity as String)

  if (change.entity != null && entity == null) {
    var importedEntity = findEntityImportById(schemat, change.entity);
    if (importedEntity == null) {
      console.log("invalid entity view entity hope its an import !", change.entity, schemat);
      //throw new Error("invalid entity view entity " + change.entity);
      importedEntity = new Entity(change.entity, importedEntityPlaceholderName, true, undefined, undefined, undefined, true);
    }
    entity = importedEntity;
  }

  var entityView = getEntityViewById(schemat, change.id);
  if (entityView == null) {
    if (change.id == null) {
      change.id = newid()
    }
    entityView = new EntityView(change.id, entity as Entity, 0, 0, 10, 10)
    schemat.entityViews.push(entityView);
  }
  applyEntityViewChange(entityView, change);
}

function applyRoleViewChangeWithContext(schemat: Schemat, change: RoleViewChange) {
  if (ChangeAction.Delete == change._a) {
    if (!change.id) {
      throw new Error("entity view delete has no id");
    }
    var roleView = getRoleViewById(schemat, change.id)
    if (roleView == null) {
      throw new Error("cant find role view to delete " + change.id);
    }
    schemat.roleViews = schemat.roleViews.filter((c) => { return change.id != c.id });
    return;
  }
  var role = getRoleById(schemat, change.role as String)

  var roleView = getRoleViewById(schemat, change.id);
  if (roleView == null) {
    if (change.id == null) {
      console.log(`*********creating role view no match for ${change.id}`)
      change.id = newid()
    }
    roleView = new RoleView(change.id, role as Role, new ViewPosition(0, 0, 10, 10))
    schemat.roleViews.push(roleView);
  }
  applyRoleViewChange(roleView, change);
}

function applyPathViewChangeWithContext(schemat: Schemat, change: PathViewChange) {
  if (ChangeAction.Delete == change._a) {
    if (!change.id) {
      throw new Error("path view delete has no id");
    }
    var pathView = getPathViewById(schemat, change.id)
    if (pathView == null) {
      throw new Error("cant find role view to delete " + change.id);
    }
    schemat.pathViews = schemat.pathViews.filter((c) => { return change.id != c.id });
    return;
  }
  var path = getPathById(schemat, change.path as String)

  var pathView = getPathViewById(schemat, change.id);
  if (pathView == null) {
    if (change.id == null) {
      console.log(`*********creating path view no match for ${change.id}`)
      change.id = newid()
    }
    pathView = new PathView(change.id, path as Path, new ViewPosition(0, 0, 10, 10))
    schemat.pathViews.push(pathView);
  }
  applyPathViewChange(pathView, change);

}

function applySchematChange(schemat: Schemat, change: SchematChange) {
  if (change.name != null) schemat.name = change.name
  if (change.version != null) schemat.version = change.version
  if (change.viewPort != null) schemat.viewPort =
    new RectangleBounds(change.viewPort.x, change.viewPort.y, change.viewPort.width, change.viewPort.height)
  if (change.diagramWidth != null) schemat.diagramWidth = change.diagramWidth
  if (change.diagramHeight != null) schemat.diagramHeight = change.diagramHeight
  if (change.toolbarTopLeft != null) schemat.toolbarTopLeft = new Point(change.toolbarTopLeft.x, change.toolbarTopLeft.y)
}

export function apply(schemat: Schemat, change: SchematChange) {
  schemat.id = change.id
  applySchematChange(schemat, change);
  //TODO sort this out !
  if (change.imports) schemat.imports = change.imports.map((imp) => {
    return { id: imp } as Schemat
  })
  if (change.entities != null) {
    change.entities.forEach(
      (ce) => {
        applyEntityChangeWithContext(schemat, ce);
      }
    )
  }
  if (change.connections != null) {
    change.connections.forEach(
      (ce) => {
        applyConnectionChangeWithContext(schemat, ce);
      }
    )
  }
  if (change.paths != null) {
    change.paths.forEach(
      (ce) => {
        applyPathChangeWithContext(schemat, ce);
      }
    )
  }
  if (change.roles != null) {
    change.roles.forEach(
      (ce) => {
        applyRoleChangeWithContext(schemat, ce);
      }
    )
  }
  //console.log("apply entity views ", change.entityViews)
  if (change.entityViews != null) {
    change.entityViews.forEach(
      (ce) => {
        applyEntityViewChangeWithContext(schemat, ce);
      }
    );
  }
  if (change.roleViews != null) {
    change.roleViews.forEach(
      (ce) => {
        applyRoleViewChangeWithContext(schemat, ce);
      }
    );
  }
  if (change.pathViews != null) {
    change.pathViews.forEach(
      (ce) => {
        applyPathViewChangeWithContext(schemat, ce);
      }
    );
  }
}


export function resolveImportLinks(schemat: Schemat) {


  // now resolve imports !
  schemat.entityViews.forEach((ev) => {
    const importedEntity = findEntityImportById(schemat, ev.entity.id);
    if (importedEntity != null) {
      ev.entity = importedEntity;
    } else if (importedEntityPlaceholderName === ev.entity.name) {
      console.log("can find import ", schemat, ev.entity.id);
    }
  });


  const allSchemas = [schemat].concat(schemat.imports)

  for (var i = 0; i < allSchemas.length; i++) {
    const currentSchemat = allSchemas[i]
    //resolve all the unresolved entities
    currentSchemat.paths.forEach((path) => {
      if (path.root && path.root.name==importedEntityPlaceholderName) {
        const importedEntity = findEntityImportById(schemat,path.root.id);
        if (!importedEntity) {
          console.log(`cant find imported entity path root ${path.root.id} in ${path.name}`, schemat)
          throw new Error(`cant find imported entity path root ${path.root.id} in ${path.name}`)
        }
        path.root = importedEntity
      }
    })

    currentSchemat.connections.forEach((con) => {
      var importedEntity;
      importedEntity = findEntityImportById(schemat, con.entity1.id);
      if (importedEntity != null) {
        con.entity1 = importedEntity;
      } else if (importedEntityPlaceholderName === con.entity1.name) {
        console.log("can find import connection entity1 ", schemat, con.entity1.id);
      }
      importedEntity = findEntityImportById(schemat, con.entity2.id);
      if (importedEntity != null) {
        con.entity2 = importedEntity;
      } else if (importedEntityPlaceholderName === con.entity2.name) {
        console.log("can find import connection entity2 ", schemat, con.entity2.id);
      }
    });

    for (var pi = 0; currentSchemat.paths && pi < currentSchemat.paths.length; pi++) {
      const allPathSegments = getAllPathSegments(currentSchemat.paths[pi].segments, []) as PathSegment[]

      for (var psi = 0; psi < allPathSegments.length; psi++) {
        const segment = allPathSegments[psi]
        if (segment.connection && segment.connection.name == importedEntityPlaceholderName) {
          const newConnection = segment.connection = getConnectionByIdSearchimports(schemat, segment.connection.id) as Connection
          if (!newConnection) {
            console.log("cant find imported connection in segment ", segment, schemat)
            throw new Error(`cant find imported connection in segment ${segment.id} connection ${segment.connection.id}`)
          }
          segment.connection = newConnection
        }
      }
    }

  }
}

export function rebuildSchemat(events: SchematChange[], id: String): Schemat {
  let result = defaultSchemat(id)
  events.forEach((event) => {
    apply(result, event)
  })

  return result
}

// TODO switch use __ref property intead of making assumptions about id property
// OR deserialize using schemact map
function resolveIdsInner(item: object, id2Object: Dictionary, collect: boolean, complexRefs: boolean) {
  if (item.hasOwnProperty("id")) {
    const id = (item as Dictionary)["id"] as unknown as string
    id2Object[id] = item
    for (var prop in item) {
      if (prop == "id") {
        continue
      }
      const val = (item as Dictionary)[prop]
      if (val) {
        if (Array.isArray(val)) {
          const aVal = val as []
          for (var i = 0; i < aVal.length; i++) {
            const subVal = aVal[i] as Dictionary
            if (typeof (subVal) === 'object') {
              if (complexRefs && !collect && subVal[refField]) {
                aVal[i] = id2Object[subVal[refField] as unknown as string] as never
              } else {
                resolveIdsInner(subVal, id2Object, collect, complexRefs)
              }
          
            } else if (!complexRefs && !collect && id2Object[subVal]) {
              aVal[i] = id2Object[subVal] as never
            }
          }
        }
        else if (typeof (val) === 'object') {
          if (complexRefs && !collect && (val as Dictionary) [refField]) {
            (item as Dictionary)[prop] = id2Object[ (val as Dictionary) [refField] as unknown as string]
          } else {
          resolveIdsInner(val as object, id2Object, collect, complexRefs)
          }
        }
         if ( !complexRefs && id2Object[val as unknown as string] && !collect) {
          (item as Dictionary)[prop] = id2Object[val as unknown as string]  
         }  
      }
    }
  }
}

export function resolveIds(item: object) {
  const id2Object = {} as Dictionary
  resolveIdsInner(item, id2Object, true, true)
  resolveIdsInner(item, id2Object, false, true)
  //console.log("resolveIds id2Object: ", id2Object)
}
