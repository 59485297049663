export class RectangleBounds {
    constructor (public x: number, public y: number, public width: number, public height: number) {}
    renderAsSvg() : String {
        return `${this.x} ${this.y} ${this.width} ${this.height}`
    }
    static renderAsSvgS(r: RectangleBounds) : String {
        return `${r.x} ${r.y} ${r.width} ${r.height}`
    }
}

export class Point {
    constructor (public x: number, public y: number) {}
    renderAsSvg() : String {
        return `${this.x} ${this.y}`
    }
}

export interface Shape {
    renderAsSvg() : String
    isIn(p: Point) : boolean
    highlight(value: boolean)
    highlighted: boolean
 } 

export interface ShapeChange {
}


