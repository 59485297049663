import { Component, OnInit, HostListener, EventEmitter, Output, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Schemat, Entity, Connection, Path, Role, PathView } from '../../schemat'
import { Point, Shape, RectangleBounds } from '../../schemat-externals'
import { asEntityShape, ConnectionShape, asConnectionShapes, asRoleShape, asPathShape } from '../../schemat-util';
import { getAllPathSegments } from '../../schemat-read-util'


@Component({
  selector: 'app-diagram',
  templateUrl: './diagram.component.html',
  styleUrls: ['./diagram.component.css']
})
export class DiagramComponent implements OnInit {

  connectionShapes: ConnectionShape[]
  public shapes: Shape[] = []

  constructor(private sanitizer: DomSanitizer) { }

  connectionShapeIsIn(diagramPoint: Point): ConnectionShape {
    if (this.connectionShapes != null) {
      for (var i = 0; i < this.connectionShapes.length; i++) {
        if (this.connectionShapes[i].isIn(diagramPoint)) {
          return this.connectionShapes[i];
        }
      }
    }
    return null;
  }


  //  shapes: Shape[] = []

  @Input() schemat: Schemat
  @Input() cursor: String

  translateToDiagramCoordinates(p: Point): Point {
    //TODO needs to take account of this.schemat.viewPort.x,y
    return new Point((p.x * this.schemat.viewPort.width / this.schemat.diagramWidth) + this.schemat.viewPort.x,
      (p.y * this.schemat.viewPort.height / this.schemat.diagramHeight) + this.schemat.viewPort.y)
  }

  drop(event: object) {
  }



  renderShapes(): SafeHtml {
    this.connectionShapes = asConnectionShapes(this.schemat);
    var str = `<svg width="${this.schemat.diagramWidth}" height="${this.schemat.diagramHeight}" viewbox="${RectangleBounds.renderAsSvgS(this.schemat.viewPort)}" >`
    //console.log("this.schemat.pathViews.length", this.schemat.pathViews.length)
    if (this.schemat != null) {
      this.connectionShapes.forEach((c) => {
        str = str + "\r\n" + c.renderAsSvg()
      })
      this.schemat.entityViews.forEach((e) => {
        str = str + "\r\n" + (asEntityShape(e)).renderAsSvg()
      })

      this.schemat.roleViews.forEach((r) => {
        const roleShape = (asRoleShape(r))
        str = str + "\r\n" + roleShape.renderAsSvg()
        // for each attached path find the path view
        const pathViews: PathView[] = []
        //console.log(`checking ${r.role.name} paths`)
        r.role.paths.forEach((p) => {
          const pvs4Path = this.schemat.pathViews.filter((pv) => { return pv.path == p })
          //console.log(`adding ${pathViews.length} path views to connect role to`)
          pvs4Path.forEach((pv) => { pathViews.push(pv) })
        })
        //console.log(`displaying  ${pathViews.length} path views to connect role to`)

        pathViews.forEach((pv)=>{
          str = str + "\r\n" + roleShape.renderPathLinkAsSvg(pv, true)
        })
      })

        this.schemat.pathViews.forEach((pathView) => {
          const entityViews = this.schemat.entityViews.filter((ev) => { return ev.entity == pathView.path.root })
          const pathShape = asPathShape(pathView)

          entityViews.forEach((ev) => {
            str = str + "\r\n" + pathShape.renderEntityLinkAsSvg(ev, false)
          })

          const subEntityIds = {}
          getAllPathSegments(pathView.path.segments, []).forEach((segment) => {
            if (segment.connection && segment.connection.entity2 != pathView.path.root) subEntityIds[segment.connection.entity2.id as string] = 'x'
          })
          this.schemat.entityViews
            .filter((ev) => { if (subEntityIds[ev.entity.id as string]) return true; else return false })
            .forEach((ev) => str = str + "\r\n" + pathShape.renderEntityLinkAsSvg(ev, true))

          str = str + "\r\n" + pathShape.renderAsSvg()

        })

        this.shapes.forEach((s) => {
          str = str + "\r\n" + s.renderAsSvg()
        })
      }
    str = str + "</svg>"
    return this.sanitizer.bypassSecurityTrustHtml(str);
    }

    svg: SafeHtml;


    defaultX = 10
    defaultY = 10
    defaultStrokeWidth = 1

    testAddShape() {
      /*    this.shapes.push(new Circle(this.defaultX, this.defaultY, 10, this.defaultStrokeWidth))
          this.defaultX += 30
          this.defaultY += 40
          this.defaultStrokeWidth++
          this.svg = this.renderShapes()*/
    }

    zoom(z: number) {
      this.schemat.viewPort.height *= (1 - (0.1) * z)
      this.schemat.viewPort.width *= (1 - (0.1) * z)
      this.svg = this.renderShapes()
    }

    render() {
      this.svg = this.renderShapes()
    }

    move(p: Point) {
      this.schemat.viewPort.x += (p.x * 0.1 * this.schemat.viewPort.width)
      this.schemat.viewPort.y += (p.y * 0.1 * this.schemat.viewPort.height)
      this.svg = this.renderShapes()
    }

    zoomout() {
      this.schemat.viewPort.height *= 1.1
      this.schemat.viewPort.width *= 1.1
      this.svg = this.renderShapes()
    }

    testChangeViewPort() {
      //this.svg = this.sanitizer.bypassSecurityTrustHtml(`<svg>${this.renderShapesTest()}</svg>`);
      this.schemat.viewPort.height *= 1.1
      this.schemat.viewPort.width *= 1.1
      this.svg = this.renderShapes()
    }

    ngOnInit() {
      this.svg = this.renderShapes()//this.sanitizer.bypassSecurityTrustHtml(`<svg>${this.renderShapes()}</svg>`);
    }

    @HostListener('dblclick', ['$event'])
    onDoubleClick(event: MouseEvent) {
      var diagramPoint = this.translateToDiagramCoordinates(new Point(event.offsetX, event.offsetY))
      this.schemat.entityViews.forEach(
        (s, _, __) => {
          if ((asEntityShape(s)).isIn(diagramPoint)) {
            this.navigateEntity.emit(s.entity)
          }
        }
      )
      this.schemat.pathViews.forEach(
        (s, _, __) => {
          if ((asPathShape(s)).isIn(diagramPoint)) {
            this.navigatePath.emit(s.path)
          }
        }
      )
      this.schemat.roleViews.forEach(
        (s, _, __) => {
          if ((asRoleShape(s)).isIn(diagramPoint)) {
            this.navigateRole.emit(s.role)
          }
        }
      )
      this.connectionShapes.forEach(
        (c, _, __) => {
          if (c.isIn(diagramPoint)) {
            const connections = this.schemat.connections.filter((con) => {
              return con.entity1 === c.entity1.entity && con.entity2 === c.entity2.entity;
            })
            if (connections.length > 0) this.navigateConnection.emit(connections[0]);
          }
        }
      )
    }

    @Output()
    navigateEntity = new EventEmitter<Entity>();

    @Output()
    navigateConnection = new EventEmitter<Connection>();

    @Output()
    navigatePath = new EventEmitter<Path>();

    @Output()
    navigateRole = new EventEmitter<Role>();

  }
