import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SchematService } from 'src/app/service/schemat.service';

import {
  Schemat, Entity, SchematChange, Connection,
  ChangeAction, Path,
  PathSegmentChange, PathSegment, PermissionsChange
} from 'src/app/schemat';

import { getPathById, getRouteToPathSegmentId } from 'src/app/schemat-read-util';
import { newid } from 'src/app/schemat-util';


import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SegmentSummary, flattenAsSegmentSummaries } from '../path-form/path-form.component';

@Component({
  selector: 'app-path-segment-form',
  templateUrl: './path-segment-form.component.html',
  styleUrls: ['./path-segment-form.component.css']
})
export class PathSegmentFormComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router,
    private schematService: SchematService,
    private formBuilder: FormBuilder
  ) {
  }

  id: String
  pathId: String
  pathSegmentId: String

  schemat: Schemat
  path: Path
  pathSegment: PathSegment
  pathRoute: PathSegment[]
  parentEntity: Entity

  formGroup: FormGroup;
  titleAlert: string = 'This field is required';

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.pathId = params['pathId'];
      this.pathSegmentId = params['pathSegmentId'];
      this.schemat = this.schematService.getById(this.id)
      this.path = getPathById(this.schemat, this.pathId)
      this.pathRoute = getRouteToPathSegmentId(this.path, this.pathSegmentId)
      this.pathSegment = this.pathRoute[this.pathRoute.length - 1]  //this.path.segments.filter((segment) => { return this.pathSegmentId == segment.id })[0]
      this.parentEntity = this.pathRoute.length > 1 ? this.pathRoute[this.pathRoute.length - 2].connection.entity2 : this.path.root
    });
    this.createForm();
    this.setChangeValidate()
  }

  pathRouteAsString(): String {
    var result = this.path.name
    this.pathRoute.forEach((r) => {
      result = result + "/" + ((r.allProperties) ? "*" : r.connection.name)
    })
    return result
  }

  setChangeValidate() {
  }

  createForm() {
    const p = this.pathSegment.permissions

    this.formGroup = this.formBuilder.group({
      'connection': [this.pathSegment.connection, Validators.required],
      'allProperties': [this.pathSegment.allProperties, Validators.nullValidator],
      'read': [p!=null && p.read, Validators.nullValidator],
      'write': [p!=null && p.write, Validators.nullValidator],
      'deny': [p!=null && p.deny, Validators.nullValidator],
    });
  }

  get name() {
    return this.formGroup.get('name') as FormControl
  }
  get read() {
    return this.formGroup.get('read') as FormControl
  }
  get write() {
    return this.formGroup.get('write') as FormControl
  }
  get deny() {
    return this.formGroup.get('deny') as FormControl
  }


  get allProperties() {
    return this.formGroup.get('allProperties') as FormControl
  }

  allReferencedConnections(schemat: Schemat, fromEntity: Entity): Connection[] {
    var result = schemat.connections ? schemat.connections.filter((c) => { return c.entity1 == fromEntity }) : []
    if (schemat.imports) {
      schemat.imports.forEach((imp) => { result = result.concat(this.allReferencedConnections(imp, fromEntity)) }
      )
    }
    return result

  }

  connections(): Connection[] {
    //return this.allReferencedConnections(this.schemat, this.path)
    return this.allReferencedConnections(this.schemat, this.parentEntity)

  }

  addPathSegment() {
    console.log("addPathSegment")
    const pathSegmentChange: PathSegmentChange =
    {
      id: this.pathSegmentId, _a: ChangeAction.None,
      segments: [{ id: newid(), _a: ChangeAction.Insert, allProperties: true,
         permissions : { id: newid(), _a: ChangeAction.Insert}   
      }]
    }
    const rootChange = this.changeInContext(pathSegmentChange)
    this.schematService.change(rootChange)
  }


  segments(): SegmentSummary[] {
    return flattenAsSegmentSummaries(this.pathSegment.segments, 0, [])
    //return this.path.segments.map((s)=> { return new SegmentSummary(1, `${s.allProperties?"All Fields": " "} ${s.connection!=null && !s.allProperties ? s.connection.name: ""}`, s)  } )
  }


  changeInContext(change: PathSegmentChange): SchematChange {
    const segmentsRootChange: PathSegmentChange[] = []
    var currentSegmentsChange = segmentsRootChange
    for (var i = 0; i < this.pathRoute.length; i++) {
      const segment = this.pathRoute[i]
      var segmentChange: PathSegmentChange = { _a: ChangeAction.Update, id: segment.id, segments: [] }
      if (i == (this.pathRoute.length - 1)) {
        currentSegmentsChange.push(change)
        break
      } else {
        currentSegmentsChange.push(segmentChange)
        currentSegmentsChange = segmentChange.segments
      }
    }

    const changeRoot: SchematChange = {
      id: this.id, _a: ChangeAction.None,
      paths: [{
        id: this.pathId, _a: ChangeAction.None,
        segments: segmentsRootChange
      }]
    }
    return changeRoot
  }

  apply() {

    const newConnection = this.formGroup.value['connection'] as Connection
    var changed = false
    var permissionsChanged = false

    const segmentChange: PathSegmentChange = {
      _a: ChangeAction.Update,
      id: this.pathSegment.id
    }

    const p = this.pathSegment.permissions

    const permissionsChange: PermissionsChange = {
      _a: ChangeAction.Update,
      id:  (p) ? p.id : newid()
    }


    if (this.pathSegment.connection != newConnection) {
      segmentChange.connection = (newConnection == null ? null : newConnection.id)
      changed = true
    }

    const newAllProperties = this.formGroup.value['allProperties'] as boolean
    if (this.pathSegment.allProperties != newAllProperties) {
      changed = true
      segmentChange.allProperties = newAllProperties
    }

    const newRead = this.formGroup.value['read'] as boolean
    if (p==null && newRead || p!=null && p.read != newRead) {
      permissionsChanged = true
      permissionsChange.read=newRead
    }
    const newWrite = this.formGroup.value['write'] as boolean
    if (p==null && newRead || p!=null && p.read != newRead) {
      permissionsChanged = true
      permissionsChange.write=newWrite
    }
    const newDeny = this.formGroup.value['deny'] as boolean
    if (p==null && newDeny || p!=null && p.deny != newDeny) {
      permissionsChanged = true
      permissionsChange.deny=newDeny
    }

    if (changed || permissionsChanged) {
      if (permissionsChanged) {
        segmentChange.permissions=permissionsChange
      }
      const change: SchematChange = this.changeInContext(segmentChange)
      this.schematService.change(change)
    }

  }

  editSegment(segment: PathSegment) {
    console.log(`segment: `, segment);
    // pathsegment/:id/:pathId/:pathSegmentId
    this.router.navigate(['pathsegment', this.id, this.pathId, segment.id])
  }

  deletable(): boolean {
    return true
  }

  delete() {
    const segmentChange: PathSegmentChange = {
      _a: ChangeAction.Delete,
      id: this.pathSegment.id,
    }

    const change: SchematChange = this.changeInContext(segmentChange)
    this.schematService.change(change)
    this.back()
  }

  back() {
    if (this.pathRoute.length < 2) {
      this.router.navigate(['path', this.id, this.pathId])
    } else {
      this.router.navigate(['pathsegment', this.id, this.pathId, this.pathRoute[this.pathRoute.length - 2].id])
    }
  }

  save() {
    this.apply()
    this.back()
  }

  cancel() {
    this.back()
  }

}



