


export function renderRoleAsSvg(x: number, y: number, width: number, height: number, highlighted: boolean, name: String) {
//     var result = `<svg  x="${this.position().x}" y="${this.position().y}" height="${this.position().height}" width="${this.position().width}" viewBox="0 -52 512.00001 630" width="512pt" stroke="red" color="red"

  var strokeColor1: string = highlighted ? "red" : "blue"
  var strokeColor2: string = highlighted ? "green" : "blue"
  const textColor = "black"

 return `
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="${x}" y="${y}"
         width=${width} height=${height}
         viewBox="0 0 480 580" style="enable-background:new 0 0 480 480;" xml:space="preserve" fill="${strokeColor1}">
    <g >
        <g>
            <path d="M216.004,232c-0.001,0-0.003,0-0.004,0h-32c-4.417-0.001-7.999,3.579-8,7.996c0,0.001,0,0.003,0,0.004v55.836
                c-0.016,12.668-9.799,23.179-22.434,24.102c4.233-7.266,6.454-15.529,6.434-23.938c0-26.51-21.49-48-48-48
                c-26.51,0-48,21.49-48,48c-0.02,8.409,2.201,16.672,6.434,23.938C57.799,319.015,48.016,308.504,48,295.836V240
                c0.001-4.417-3.579-7.999-7.996-8c-0.001,0-0.003,0-0.004,0H8c-4.417-0.001-7.999,3.579-8,7.996c0,0.001,0,0.003,0,0.004v72
                c0.04,32.238,24.02,59.429,56,63.5V472c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0h96
                c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004v-96.5c31.98-4.071,55.96-31.262,56-63.5v-72
                C224.001,235.583,220.421,232.001,216.004,232z M208,312c-0.029,26.498-21.502,47.971-48,48c-4.417-0.001-7.999,3.579-8,7.996
                c0,0.001,0,0.003,0,0.004v96H72v-96c0.001-4.417-3.579-7.999-7.996-8c-0.001,0-0.003,0-0.004,0c-26.498-0.029-47.971-21.502-48-48
                v-64h16v47.836c0.024,22.172,17.992,40.14,40.164,40.164h16.055c4.417,0,7.999-3.581,7.999-7.998c0-2.528-1.194-4.907-3.222-6.416
                C84.832,315.618,80.004,306.113,80,296c0-17.673,14.327-32,32-32s32,14.327,32,32c-0.004,10.113-4.832,19.618-12.996,25.586
                c-3.543,2.638-4.277,7.649-1.639,11.192c1.509,2.027,3.888,3.222,6.416,3.222h16.055c22.172-0.024,40.14-17.992,40.164-40.164V248
                h16V312z"/>
        </g>
    </g>
    <g>
        <g  fill="${strokeColor2}">
            <path d="M464.004,232c-0.001,0-0.003,0-0.004,0h-32c-4.417-0.001-7.999,3.579-8,7.996c0,0.001,0,0.003,0,0.004v55.836
                c-0.016,12.668-9.799,23.179-22.434,24.102c4.233-7.266,6.454-15.529,6.434-23.938c0-26.51-21.49-48-48-48
                c-26.51,0-48,21.49-48,48c-0.02,8.409,2.201,16.672,6.434,23.938c-12.634-0.922-22.418-11.434-22.434-24.102V240
                c0.001-4.417-3.579-7.999-7.996-8c-0.001,0-0.003,0-0.004,0h-32c-4.417-0.001-7.999,3.579-8,7.996c0,0.001,0,0.003,0,0.004v72
                c0.04,32.238,24.02,59.429,56,63.5V472c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0h96
                c4.417,0.001,7.999-3.579,8-7.996c0-0.001,0-0.003,0-0.004v-96.5c31.98-4.071,55.96-31.262,56-63.5v-72
                C472.001,235.583,468.421,232.001,464.004,232z M456,312c-0.029,26.498-21.502,47.971-48,48c-4.417-0.001-7.999,3.579-8,7.996
                c0,0.001,0,0.003,0,0.004v96h-80v-96c0.001-4.417-3.579-7.999-7.996-8c-0.001,0-0.003,0-0.004,0
                c-26.498-0.029-47.971-21.502-48-48v-64h16v47.836c0.024,22.172,17.992,40.14,40.164,40.164h16.055
                c4.417,0,7.999-3.581,7.999-7.998c0-2.528-1.194-4.907-3.222-6.416C332.832,315.618,328.004,306.113,328,296
                c0-17.673,14.327-32,32-32c17.673,0,32,14.327,32,32c-0.004,10.113-4.832,19.618-12.996,25.586
                c-3.543,2.638-4.277,7.649-1.639,11.192c1.51,2.027,3.888,3.222,6.416,3.222h16.055c22.172-0.024,40.14-17.992,40.164-40.164V248
                h16V312z"/>
        </g>
    </g>
    <g>
        <g>
            <path d="M473.563,91.484l-11.187-2.234c-0.395-1.609-0.832-3.224-1.313-4.844l7.996-7.156c2.55-2.286,3.362-5.943,2.02-9.094
                c-3.605-8.362-8.214-16.253-13.726-23.5c-2.067-2.724-5.632-3.84-8.883-2.781l-10.035,3.297c-1.168-1.234-2.369-2.435-3.602-3.602
                l3.297-10.031c1.066-3.25-0.05-6.819-2.777-8.883c-7.245-5.518-15.137-10.13-23.5-13.734c-3.151-1.351-6.813-0.536-9.094,2.023
                l-7.164,7.992c-1.617-0.484-3.233-0.922-4.848-1.312l-2.234-11.187C387.768,2.695,384.483,0,380.668,0h-25.336
                c-3.815,0-7.099,2.695-7.844,6.438l-2.234,11.188c-1.613,0.391-3.229,0.828-4.848,1.312l-7.16-7.992
                c-2.282-2.557-5.943-3.372-9.094-2.024c-8.361,3.603-16.252,8.212-23.496,13.727c-2.73,2.062-3.848,5.632-2.781,8.883
                l3.297,10.031c-1.23,1.172-2.432,2.375-3.605,3.609l-10.035-3.297c-3.25-1.059-6.814,0.058-8.879,2.781
                c-5.517,7.244-10.127,15.136-13.727,23.5c-1.344,3.152-0.53,6.809,2.023,9.094l7.988,7.156c-0.481,1.609-0.917,3.224-1.309,4.844
                l-11.191,2.234c-3.742,0.745-6.437,4.028-6.437,7.844v25.344c0,3.815,2.695,7.099,6.438,7.844l11.188,2.234
                c0.395,1.609,0.832,3.224,1.313,4.844l-7.996,7.156c-2.551,2.289-3.363,5.948-2.02,9.102c3.607,8.358,8.216,16.246,13.727,23.492
                c2.067,2.723,5.632,3.839,8.883,2.781l10.035-3.297c1.168,1.234,2.369,2.435,3.602,3.602l-3.297,10.031
                c-1.066,3.251,0.051,6.82,2.781,8.883c7.245,5.516,15.135,10.128,23.496,13.734c3.152,1.34,6.808,0.527,9.094-2.023l7.164-7.992
                c1.617,0.484,3.233,0.922,4.848,1.313l2.234,11.187c0.745,3.742,4.028,6.437,7.843,6.437h25.336c3.815,0,7.099-2.695,7.844-6.438
                l2.234-11.187c1.613-0.391,3.229-0.828,4.848-1.313l7.16,7.992c2.285,2.552,5.942,3.366,9.094,2.023
                c8.361-3.603,16.252-8.212,23.496-13.726c2.73-2.059,3.848-5.626,2.781-8.875l-3.297-10.039c1.23-1.172,2.431-2.375,3.602-3.609
                l10.039,3.297c3.25,1.059,6.814-0.057,8.879-2.781c5.517-7.244,10.127-15.136,13.727-23.5c1.343-3.151,0.531-6.807-2.02-9.094
                l-7.992-7.156c0.48-1.609,0.917-3.224,1.309-4.844l11.191-2.234c3.742-0.745,6.437-4.029,6.437-7.844V99.328
                C480,95.513,477.305,92.229,473.563,91.484z M464,118.109l-9.918,1.977c-3.22,0.641-5.722,3.184-6.309,6.414
                c-0.801,4.375-1.961,8.676-3.469,12.859c-1.102,3.064-0.237,6.49,2.187,8.664l7.473,6.695c-1.811,3.58-3.845,7.042-6.09,10.367
                l-9.41-3.086c-3.093-1.01-6.491-0.05-8.598,2.43c-2.882,3.397-6.038,6.551-9.438,9.43c-2.487,2.104-3.449,5.506-2.434,8.602
                l3.09,9.414c-3.326,2.246-6.79,4.282-10.371,6.094l-6.691-7.469c-2.176-2.415-5.592-3.282-8.656-2.195
                c-4.182,1.504-8.48,2.662-12.852,3.461c-3.237,0.583-5.788,3.087-6.43,6.313L374.105,208h-12.211l-1.98-9.922
                c-0.642-3.226-3.193-5.73-6.43-6.313c-4.372-0.799-8.67-1.957-12.852-3.461c-3.063-1.094-6.484-0.227-8.656,2.195l-6.695,7.469
                c-3.58-1.816-7.044-3.854-10.371-6.102l3.09-9.398c1.016-3.094,0.055-6.496-2.43-8.602c-3.4-2.883-6.557-6.039-9.441-9.438
                c-2.107-2.48-5.504-3.44-8.598-2.43l-9.402,3.086c-2.246-3.325-4.281-6.787-6.094-10.367l7.473-6.695
                c2.423-2.174,3.288-5.6,2.187-8.664c-1.508-4.181-2.668-8.48-3.469-12.852c-0.583-3.234-3.085-5.782-6.309-6.422L272,118.109
                v-12.219l9.922-1.977c3.223-0.64,5.726-3.188,6.309-6.422c0.801-4.374,1.961-8.675,3.469-12.859
                c1.098-3.066,0.23-6.491-2.195-8.664l-7.469-6.687c1.814-3.578,3.847-7.041,6.09-10.367l9.41,3.086
                c3.093,1.006,6.488,0.047,8.598-2.43c2.884-3.402,6.043-6.561,9.445-9.445c2.481-2.108,3.44-5.508,2.426-8.602l-3.09-9.398
                c3.326-2.247,6.79-4.282,10.371-6.094l6.691,7.469c2.171,2.424,5.593,3.291,8.656,2.195c4.182-1.504,8.48-2.662,12.852-3.461
                c3.237-0.583,5.788-3.087,6.43-6.313L361.895,16h12.211l1.98,9.922c0.642,3.226,3.193,5.73,6.43,6.313
                c4.372,0.799,8.67,1.957,12.852,3.461c3.064,1.084,6.479,0.218,8.656-2.195l6.695-7.469c3.581,1.814,7.046,3.849,10.375,6.094
                L418,41.531c-1.016,3.094-0.055,6.496,2.43,8.602c3.4,2.883,6.557,6.039,9.441,9.438c2.109,2.477,5.504,3.436,8.598,2.43
                l9.402-3.086c2.246,3.326,4.282,6.788,6.098,10.367l-7.477,6.695c-2.423,2.174-3.288,5.6-2.187,8.664
                c1.507,4.181,2.668,8.48,3.469,12.852c0.583,3.234,3.085,5.782,6.309,6.422l9.918,1.977V118.109z"/>
        </g>
    </g>
    <g>
        <g  fill="${strokeColor2}">
            <path d="M368,48c-35.346,0-64,28.654-64,64c0,35.346,28.654,64,64,64c35.346,0,64-28.654,64-64
                C431.96,76.671,403.329,48.04,368,48z M368,160c-26.51,0-48-21.49-48-48s21.49-48,48-48c26.51,0,48,21.49,48,48
                C415.971,138.498,394.498,159.971,368,160z"/>
        </g>
    </g>
    <g>
        <g>
            <path d="M407.155,108.42c-0.774-1.547-2.029-2.802-3.576-3.576l-48-24c-2.48-1.242-5.427-1.109-7.785,0.352
                c-2.357,1.459-3.792,4.033-3.793,6.805v48c0.001,4.419,3.584,8.001,8.003,8c1.241,0,2.465-0.289,3.575-0.844l48-24
                C407.53,117.179,409.132,112.372,407.155,108.42z M360,123.055v-22.109L382.113,112L360,123.055z"/>
        </g>
    </g>
    <g>
    <g  fill="${strokeColor2}">
    <path d="M216.797,91.5l-10.41-2.188c-0.395-1.633-0.836-3.273-1.324-4.906l7.996-7.156c2.55-2.286,3.362-5.943,2.02-9.094
                c-3.605-8.361-8.214-16.253-13.726-23.5c-2.067-2.724-5.632-3.84-8.883-2.781l-10.035,3.297c-1.168-1.234-2.369-2.435-3.602-3.602
                l3.297-10.031c1.066-3.25-0.05-6.819-2.777-8.883c-7.245-5.518-15.137-10.13-23.5-13.734c-3.151-1.351-6.813-0.536-9.094,2.023
                l-7.164,7.992c-1.617-0.484-3.233-0.922-4.848-1.312l-2.234-11.187C131.768,2.695,128.483,0,124.668,0H99.332
                c-3.815,0-7.099,2.695-7.844,6.438l-2.234,11.188c-1.613,0.391-3.229,0.828-4.848,1.312l-7.16-7.992
                c-2.282-2.557-5.943-3.372-9.094-2.024c-8.361,3.603-16.252,8.212-23.496,13.727c-2.73,2.062-3.848,5.632-2.781,8.883
                l3.297,10.031c-1.23,1.172-2.432,2.375-3.605,3.609l-10.035-3.297c-3.25-1.059-6.813,0.058-8.879,2.781
                c-5.517,7.244-10.127,15.136-13.727,23.5c-1.344,3.152-0.53,6.809,2.023,9.094l7.988,7.156c-0.48,1.609-0.917,3.224-1.309,4.844
                L6.438,91.484C2.695,92.229,0,95.513,0,99.328v25.344c0,3.815,2.695,7.099,6.438,7.844l11.188,2.234
                c0.395,1.609,0.832,3.224,1.312,4.844l-7.996,7.156c-2.551,2.289-3.363,5.948-2.02,9.102c3.607,8.358,8.216,16.246,13.727,23.492
                c2.067,2.724,5.632,3.84,8.883,2.781l10.035-3.297c1.168,1.234,2.369,2.435,3.602,3.602l-3.297,10.031
                c-1.066,3.251,0.051,6.82,2.781,8.883c7.245,5.516,15.135,10.128,23.496,13.734c3.152,1.34,6.808,0.527,9.094-2.023l7.164-7.992
                c1.617,0.484,3.233,0.922,4.848,1.313l2.234,11.187c0.745,3.742,4.028,6.437,7.843,6.437h25.336c3.815,0,7.099-2.695,7.844-6.438
                l2.234-11.187c1.613-0.391,3.229-0.828,4.848-1.313l7.16,7.992c2.285,2.552,5.942,3.366,9.094,2.023
                c8.361-3.603,16.252-8.212,23.496-13.726c2.73-2.059,3.848-5.626,2.781-8.875l-3.297-10.039c1.23-1.172,2.431-2.375,3.602-3.609
                l10.039,3.297c3.25,1.059,6.814-0.058,8.879-2.781c5.517-7.244,10.127-15.136,13.727-23.5c1.343-3.151,0.531-6.807-2.02-9.094
                l-7.992-7.156c0.484-1.633,0.926-3.268,1.324-4.906l10.41-2.188c3.703-0.778,6.355-4.044,6.355-7.828V99.328
                C223.152,95.544,220.5,92.277,216.797,91.5z M207.152,118.18L198,120.102c-3.185,0.666-5.647,3.197-6.227,6.398
                c-0.801,4.375-1.961,8.676-3.469,12.859c-1.102,3.064-0.237,6.49,2.187,8.664l7.473,6.695c-1.811,3.58-3.845,7.042-6.09,10.367
                l-9.41-3.086c-3.093-1.01-6.491-0.05-8.598,2.43c-2.882,3.397-6.038,6.551-9.438,9.43c-2.487,2.104-3.449,5.506-2.434,8.602
                l3.09,9.414c-3.326,2.246-6.79,4.282-10.371,6.094l-6.691-7.469c-2.176-2.415-5.592-3.281-8.656-2.195
                c-4.182,1.504-8.48,2.662-12.852,3.461c-3.237,0.583-5.788,3.087-6.43,6.313L118.105,208h-12.211l-1.981-9.922
                c-0.642-3.226-3.193-5.73-6.43-6.313c-4.372-0.799-8.67-1.957-12.852-3.461c-3.063-1.093-6.484-0.226-8.656,2.195l-6.695,7.469
                c-3.58-1.816-7.044-3.854-10.371-6.102l3.09-9.398c1.016-3.094,0.055-6.496-2.43-8.602c-3.4-2.883-6.557-6.039-9.441-9.438
                c-2.107-2.48-5.504-3.44-8.598-2.43l-9.402,3.086c-2.246-3.325-4.281-6.787-6.094-10.367l7.473-6.695
                c2.423-2.174,3.288-5.6,2.187-8.664c-1.507-4.181-2.668-8.48-3.469-12.852c-0.583-3.234-3.085-5.782-6.309-6.422L16,118.109
                v-12.219l9.922-1.977c3.223-0.64,5.726-3.188,6.309-6.422c0.801-4.374,1.961-8.675,3.469-12.859
                c1.098-3.066,0.23-6.491-2.195-8.664l-7.469-6.687c1.814-3.578,3.848-7.041,6.09-10.367L41.535,62
                c3.094,1.005,6.488,0.046,8.598-2.43c2.884-3.402,6.043-6.561,9.445-9.445c2.481-2.108,3.44-5.508,2.426-8.602l-3.09-9.398
                c3.326-2.247,6.79-4.282,10.371-6.094l6.691,7.469c2.171,2.423,5.593,3.29,8.656,2.195c4.182-1.504,8.48-2.662,12.852-3.461
                c3.237-0.583,5.788-3.087,6.43-6.313L105.895,16h12.211l1.98,9.922c0.642,3.226,3.193,5.73,6.43,6.313
                c4.372,0.799,8.67,1.957,12.852,3.461c3.064,1.083,6.478,0.217,8.656-2.195l6.695-7.469c3.581,1.814,7.046,3.849,10.375,6.094
                L162,41.531c-1.016,3.094-0.055,6.496,2.43,8.602c3.4,2.883,6.557,6.039,9.441,9.438c2.109,2.477,5.504,3.436,8.598,2.43
                l9.402-3.086c2.246,3.326,4.282,6.788,6.098,10.367l-7.477,6.695c-2.423,2.174-3.288,5.6-2.187,8.664
                c1.507,4.181,2.668,8.48,3.469,12.852c0.579,3.203,3.041,5.737,6.227,6.406l9.152,1.922V118.18z"/>
        </g>
    </g>
    <g>
        <g>
            <path d="M112,48c-35.346,0-64,28.654-64,64c0.04,35.329,28.671,63.96,64,64c35.346,0,64-28.654,64-64
                C176,76.654,147.346,48,112,48z M112,160c-26.51,0-48-21.49-48-48c0.029-26.498,21.502-47.971,48-48c26.51,0,48,21.49,48,48
                S138.51,160,112,160z"/>
        </g>
    </g>
    <g>
    <g  fill="${strokeColor2}">
            <path d="M151.155,108.42c-0.774-1.547-2.029-2.802-3.576-3.576l-48-24c-3.952-1.976-8.758-0.373-10.734,3.579
                C88.289,85.533,88,86.758,88,88v48c0.001,2.772,1.436,5.346,3.793,6.805c2.359,1.458,5.304,1.591,7.785,0.352l48-24
                C151.53,117.179,153.132,112.372,151.155,108.42z M104,123.055v-22.109L126.113,112L104,123.055z"/>
        </g>
    </g>
    <g>
        <g>
            <rect x="368" y="432" width="16" height="16"/>
        </g>
    </g>
    <g>
    <g  fill="${strokeColor2}">
            <rect x="88" y="432" width="16" height="16"/>
        </g>
    </g>
    <text fill="${textColor}"  x="50%" y="550" dominant-baseline="middle" text-anchor="middle" font-size="90">${name}</text>

    </svg>
    `
}

export function renderPathAsSvg(x: number, y: number, width: number, height: number, highlighted: boolean, name: String) {
    //     var result = `<svg  x="${this.position().x}" y="${this.position().y}" height="${this.position().height}" width="${this.position().width}" viewBox="0 -52 512.00001 630" width="512pt" stroke="red" color="red"
    
      var strokeColor1: string = highlighted ? "red" : "blue"
      var strokeColor2: string = highlighted ? "green" : "blue"
      var textColor = "black"
    
     return `
     <svg id="Capa_1" enable-background="new 0 0 511.999 511.999" 
     x="${x}" y="${y}"  width=${width} height=${height}
      viewBox="0 0 511.999 621.999" 
     xmlns="http://www.w3.org/2000/svg"
     fill="${strokeColor1}"
     >
     <path d="m300.997 511.999h-90v-45c0-52.805-39.183-96.631-90-103.932v43.747l-120.996-89.815 120.997-89.815v45.398c32.07 2.496 62.946 12.957 90 30.371v-188.941h-46.209l91.208-114.012 91.209 114.012h-46.209v68.941c27.054-17.415 57.93-27.876 90-30.371v-45.397l121.001 89.815-121.001 89.814v-43.746c-50.817 7.301-90 51.127-90 103.932zm-60-30h30v-135c0-74.439 60.561-135 135-135h15v15.186l40.667-30.186-40.667-30.186v15.186h-15c-80.59 0-121.136 55.33-135 64.841v-162.828h13.791l-28.791-35.988-28.791 35.988h13.791v282.828l-25.021-22.466c-30.434-27.326-69.492-42.375-109.979-42.375h-15v-15.185l-40.664 30.185 40.664 30.185v-15.185h15c74.439 0 135 60.561 135 135z"/>
     <path fill="${strokeColor2}" d="m360.997 361.999h30v30h-30z"/>
     <path d="m420.997 361.999h91v30h-91z"/>
     <path d="m360.997 421.999h30v30h-30z"/>
     <path fill="${strokeColor2}" d="m420.997 421.999h91v30h-91z"/>
     <path fill="${strokeColor2}" d="m360.997 481.999h30v30h-30z"/><path d="m420.997 481.999h91v30h-91z"/>
     <text fill="${textColor}"  x="50%" y="590" dominant-baseline="middle" text-anchor="middle" font-size="90">${name}</text>
     </svg>
     `

    }

export function renderPathLinkAsSvg(x1: number, y1: number, x2: number, y2: number, 
    strokeWidth: number, highlighted: boolean): String {
    var strokeColor1: string = highlighted ? "red" : "blue"
    // 5,5
    const dashArray = "11,2,7,2,5,2,3,3,1,2,3,2,5,2,7,2"
    //console.log(`renderPathLinkAsSvg ${x1}, ${y1}, ${x2}, ${y2}, ${strokeWidth}, ${strokeColor1}, ${dashArray}`)
    return renderLineAsSvg(x1, y1, x2, y2, strokeWidth, strokeColor1, dashArray)
}


    export function renderEntityLinkAsSvg(x1: number, y1: number, x2: number, y2: number, 
        strokeWidth: number, highlighted: boolean, isSublink: boolean): String {
        var strokeColor1: string = highlighted ? "red" : "blue"
        // 5,5
        const dashArray = isSublink ? "1,2" : "5,5"

        return renderLineAsSvg(x1, y1, x2, y2, strokeWidth, strokeColor1, dashArray)
    }

    export function renderLineAsSvg(x1: number, y1: number, x2: number, y2: number,
            strokeWidth: number, strokeColor: String, dasharray: String): String {
        var result =
            `<line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" stroke-dasharray="${dasharray}" style="stroke:${strokeColor};stroke-width:${strokeWidth}" />`
        return result
    }


