import * as uuid from 'uuid';

import {
    Schemat, Entity, Connection, Cardinality, Change, SchematChange,
     ConnectionType, EntityView, Role, RoleView, ViewPosition, Path, PathView, PathSegment
} from './schemat'

import { Shape, Point } from './schemat-externals'

import { renderRoleAsSvg, renderPathAsSvg, renderLineAsSvg, renderEntityLinkAsSvg, renderPathLinkAsSvg } from './shapes'

export function newid(): String {
    return uuid.v4()
}

export interface ShapeChange extends Change {
}


export function addEntity(s: Schemat, entity: Entity) {
    if (entity.id == null || entity.id.trim().length == 0) {
        //TODO fix id policy
        entity.id = "" + s.entities.length + 1
        s.entities.push(entity)
    }
}

export function addConnection(s: Schemat, entity1: Entity, entity2: Entity, name: String) {
    //TODO sort out ids
    var id = newid()//"" + s.connections.length+1
    var connection = new Connection(id, name, entity1, entity2, Cardinality.OneToOne, ConnectionType.Reference)
    s.connections.push(connection)
}

export class Circle implements Shape {

    highlighted: boolean

    highlight(value: boolean) { }


    constructor(private x: number, private y: number, private radius: number, private strokeWidth: number) {
    }

    renderAsSvg(): String {
        var result =
            `<circle  cx="${this.x}" cy="${this.x}" r="${this.radius}" fill="lightgrey" fill-opacity="1" stroke="black" stroke-width="${this.strokeWidth}"></circle>`
        return result
    }

    isIn(p: Point): boolean {
        return p.x >= this.x && p.x <= (this.x + this.radius) && p.y >= this.y && p.y <= (this.y + this.radius)
    }

}

export class Line implements Shape {

    highlight(value: boolean) { }

    highlighted: boolean

    constructor(public p1: Point, public p2: Point, public strokeWidth: number) {
    }

    renderAsSvg(): String {
        var result =
            `<line x1="${this.p1.x}" y1="${this.p1.y}" x2="${this.p2.x}" y2="${this.p2.y}" style="stroke:rgb(255,0,0);stroke-width:${this.strokeWidth}" />`
        return result
    }

    isIn(p: Point): boolean {
        // TODO
        return false
    }
}

export class ConnectionShape {

    constructor(public highlighted: boolean,
        public entity1: EntityView,
        public entity2: EntityView) { }

    //this is a transitory property

    highlight(value: boolean) {
        this.highlighted = value
    }

    private centre(entity: EntityView): Point {
        return new Point(entity.x + (entity.width / 2), entity.y + (entity.height / 2))
    }

    renderAsSvg(): String {
        var strokeWidth = 2
        var p1 = this.centre(this.entity1)
        var p2 = this.centre(this.entity2)

        var strokeColor = this.highlighted ? "red" : "rgb(50,50,50)"
        var result =
            `<line x1="${p1.x}" y1="${p1.y}" x2="${p2.x}" y2="${p2.y}" style="stroke:${strokeColor};stroke-width:${strokeWidth}" />`
        return result
    }

    isIn(p: Point): boolean {
        // see here https://en.wikipedia.org/wiki/Distance_from_a_point_to_a_line
        var p1 = this.centre(this.entity1)
        var p2 = this.centre(this.entity2)
        if ((p.x < p1.x && p.x < p2.x) ||
            (p.x > p1.x && p.x > p2.x) ||
            (p.y < p1.y && p.y < p2.y) ||
            (p.y > p1.y && p.x > p2.y)) {
            return false
        }

        /* what if vertical p1.x = p2.x
         distance = ( p2.y-p1.y  ) * p.x + p2.x * p1.y - p2.y * p2.x /~
                  = ( p2.y - p1.y) ( p.x - p2.x ) / ~
                  = p.x -p2.x     correct !
           hence horizontal will also be OK :-)         
        */

        var distance = (((p2.y - p1.y) * p.x) - ((p2.x - p1.x) * p.y) + (p2.x * p1.y) - (p2.y * p1.x)) /
            Math.sqrt(Math.pow(p2.y - p1.y, 2) + Math.pow(p2.x - p1.x, 2))

        distance = Math.abs(distance)


        //TODO remove this magic number !
        return distance <= (2)
    }
}

// Mixin local diagram functions
/*Object.getOwnPropertyNames(ConnectionShape.prototype).forEach(name => {
    Object.defineProperty(
     Connection.prototype,
    name,
    Object.getOwnPropertyDescriptor(ConnectionShape.prototype, name))
}
);
*/

export function asConnectionShapes(schemat: Schemat): ConnectionShape[] {
    //const e = connection as unknown
    const result: ConnectionShape[] = [];
    schemat.entityViews.forEach(
        (ev) => {
            schemat.connections.filter(
                (c) => { return c.entity1 === ev.entity && !c.entity2.isValueType; })
                .forEach((c) => {
                    const ev2s = schemat.entityViews.filter((ev2) => { return c.entity2 == ev2.entity })
                    ev2s.forEach((ev2) => {
                        result.push(new ConnectionShape(false, ev, ev2))
                    })
                    //
                })
        }
    );
    return result;
}


export class EntityShape implements Shape {

    constructor(public id: String, public entity: Entity, public x: number, public y: number, public height = 32, public width = 32) {
    }

    highlighted = false

    highlight(value: boolean) {
        this.highlighted = value
    }

    centre() {
        return new Point(this.x + (this.width / 2), this.y + (this.height / 2))
    }

    isIn(p: Point): boolean {
        return p.x >= this.x && p.x <= (this.x + this.width) && p.y >= this.y && p.y <= (this.y + this.height)
    }

    renderAsSvg(): String {
        const strokeColor =
            this.highlighted ? "red" : this.entity.isValueType ? "yellow" : this.entity.isExternal ? "green" : "black"
        var result = `<svg  x="${this.x}" y="${this.y}" height="${this.height}" width="${this.width}" viewBox="0 -52 512.00001 630" width="512pt" stroke="red" color="red"
     xmlns="http://www.w3.org/2000/svg">

   <g fill="${strokeColor}">
     <path d="m0 113.292969h113.292969v-113.292969h-113.292969zm30.003906-83.289063h53.289063v53.289063h-53.289063zm0 0" />
     <path d="m149.296875 0v113.292969h362.703125v-113.292969zm332.699219 83.292969h-302.695313v-53.289063h302.695313zm0 0" />
     <path d="m0 260.300781h113.292969v-113.292969h-113.292969zm30.003906-83.292969h53.289063v53.289063h-53.289063zm0 0" />
     <path d="m149.296875 260.300781h362.703125v-113.292969h-362.703125zm30.003906-83.292969h302.695313v53.289063h-302.695313zm0 0" />
     <path d="m0 407.308594h113.292969v-113.296875h-113.292969zm30.003906-83.292969h53.289063v53.289063h-53.289063zm0 0"/>
     <path d="m149.296875 407.308594h362.703125v-113.296875h-362.703125zm30.003906-83.292969h302.695313v53.289063h-302.695313zm0 0"/>
     </g>
     <text x="50%" y="550" dominant-baseline="middle" text-anchor="middle" font-size="90">${this.entity.name}</text> 
     </svg>`
        return result;
    }
}
// Mixin local diagram functions
Object.getOwnPropertyNames(EntityShape.prototype).forEach(name => {
    Object.defineProperty(
        EntityView.prototype,
        name,
        Object.getOwnPropertyDescriptor(EntityShape.prototype, name))
}
);

export function asEntityShape(entityView: EntityView): EntityShape {
    const e = entityView as unknown
    const result =  e as EntityShape
    //console.log("asEntityShape returning ", result)
    return result
}

//export function asEntityShape2(ev: EntityView): EntityShape {
//    const result = new EntityShape(ev.id, ev.entity, ev.x, ev.y, ev.height, ev.width)
//    return result
//}

export class RoleShape implements Shape {

    constructor(public id: String, public role: Role, public position: ViewPosition) {
    }

    highlighted = false

    highlight(value: boolean) {
        this.highlighted = value
    }

    centre() {
        return new Point(this.position.x + (this.position.width / 2), this.position.y + (this.position.height / 2))
    }

    isIn(p: Point): boolean {
        return p.x >= this.position.x && p.x <= (this.position.x + this.position.width) && p.y >= this.position.y && p.y <= (this.position.y + this.position.height)
    }


    renderAsSvg(): String {
        const name = this.role == null ? "noRole" : this.role.name
        var result = renderRoleAsSvg(this.position.x, this.position.y, this.position.width, this.position.height, this.highlighted, name)
        return result;
    }
    // renderPathLinkAsSvg

    renderPathLinkAsSvg(pathView: PathView, isSublink: boolean): String {
        const pos = pathView.position
        const pathViewCentreX = pos.x + (pos.width / 2)
        const pathViewCentreY = pos.y + (pos.height / 2)
        const centre = this.centre()
        return renderPathLinkAsSvg(centre.x, centre.y, pathViewCentreX, pathViewCentreY, 1, this.highlighted)
    }
}

Object.getOwnPropertyNames(RoleShape.prototype).forEach(name => {
    Object.defineProperty(
        RoleView.prototype,
        name,
        Object.getOwnPropertyDescriptor(RoleShape.prototype, name))
}
);

export function asRoleShape(roleView: RoleView): RoleShape {
    const e = roleView as unknown
    return e as RoleShape
}

export class PathShape implements Shape {

    constructor(public id: String, public path: Path, public position: ViewPosition) {
    }

    highlighted = false

    highlight(value: boolean) {
        this.highlighted = value
    }

    centre() {
        return new Point(this.position.x + (this.position.width / 2), this.position.y + (this.position.height / 2))
    }

    isIn(p: Point): boolean {
        return p.x >= this.position.x && p.x <= (this.position.x + this.position.width) && p.y >= this.position.y && p.y <= (this.position.y + this.position.height)
    }

    renderAsSvg(): String {
        //console.log("PathShape", this)

        const name = this.path == null ? "nullPath" : this.path.name
        var result = renderPathAsSvg(this.position.x, this.position.y, this.position.width, this.position.height, this.highlighted, name)
        return result;
    }

    renderEntityLinkAsSvg(entityView: EntityView, isSublink: boolean): String {
        const entityViewCentreX = entityView.x + (entityView.width / 2)
        const entityViewCentreY = entityView.y + (entityView.height / 2)
        const centre = this.centre()
        return renderEntityLinkAsSvg(centre.x, centre.y, entityViewCentreX, entityViewCentreY, 1, this.highlighted, isSublink)
    }

}


Object.getOwnPropertyNames(PathShape.prototype).forEach(name => {
    Object.defineProperty(
        PathView.prototype,
        name,
        Object.getOwnPropertyDescriptor(PathShape.prototype, name))
}
);

export function asPathShape(pathView: PathView): PathShape {
    const e = pathView as unknown
    return e as PathShape
}
