import { Component, OnInit, ViewChild, HostListener, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DiagramComponent } from '../diagram/diagram.component';
import { Point } from '../../schemat-externals'
import { Entity, Schemat, Connection, EntityChange, SchematChange, ConnectionType, Cardinality, EntityViewChange, EntityView, Role, Path,
   ChangeAction, RoleChange, RoleViewChange, RoleView, PathChange, PathViewChange, PathView } from '../../schemat'
import { Line, asEntityShape, newid, asRoleShape, asPathShape } from '../../schemat-util'

import { SchematService } from 'src/app/service/schemat.service';
import { ToolbarComponent } from '../toolbar/toolbar.component';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private schematService: SchematService) { }

  id: String
  pendingAddEntity = false
  schemat: Schemat
  toolbarTopLeft: Point = new Point(40, 30)
  cursor = "default"
  draggedEntity: EntityView = null
  draggedEntityClickPointOffset: Point
  draggedRole: RoleView = null
  draggedPath: PathView = null
  draggedRoleClickPointOffset: Point
  draggedPathClickPointOffset: Point

  connectedEntityView1: EntityView = null

  pendingAddConnection = false
  pendingAddRole = false
  pendingAddPath = false

  connectionGhost: Line = null

  toolbarTitle(): String {
    // TODO fix versions in schemas
    // : ${this.schemat.version}
    return `${this.schemat.name}` 
  }

  @ViewChild(DiagramComponent, { static: false }) diagram!: DiagramComponent;
  @ViewChild(DiagramComponent, { static: false }) toolbar!: ToolbarComponent;

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.loadSchemat()
    });
  }

  loadSchemat() {
    this.schemat = this.schematService.getById(this.id)
    //this.diagram.schemat = schemat

    this.toolbarTopLeft = new Point(this.schemat.toolbarTopLeft.x, this.schemat.toolbarTopLeft.y)

  }

  crosshairCursor() {
    this.cursor = "crosshair"
  }

  copyCursor() {
    this.cursor = "copy"
  }

  defaultCursor() {
    this.cursor = "default"
  }


  zoom(z: number) {
    this.diagram.zoom(z)
  }

  move(p: Point) {
    this.diagram.move(p)
  }

  pendingEntityImport: Entity;

  entity(e: Entity) {
    console.log("entity", e);
    this.pendingAddEntity = true;
    this.pendingEntityImport = e;
  }

  role(r: Role) {
    console.log("role", r);
    this.pendingAddRole = true;
  }

  path(p: Path) {
    console.log("path", p);
    this.pendingAddPath = true;
  }

  importEntity(e: Entity) {
    console.log("importEntity", e);
    this.pendingAddEntity = true;
    this.pendingEntityImport = e;
  }

  connection() {
    this.crosshairCursor()
    this.changeDetectorRef.detectChanges();
    if (this.pendingAddConnection) {
      this.pendingAddConnection = false
      // remove the inserted ghost
      // TODO if it wasnt null clean up
    } else {
      this.pendingAddConnection = true
      // TODO if it wasnt null clean up
      this.pendingAddEntity = null
      this.pendingEntityImport = null;
    }
  }

  drop(event: object) {
  }

  @ViewChild('toolbar', { static: false })
  toolbarElement: ElementRef;

  private refreshDisplay() {
    this.diagram.render()
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {

    if (this.pendingAddEntity || this.pendingAddPath || this.pendingAddRole) {
      var diagramPoint = this.diagram.translateToDiagramCoordinates(new Point(event.offsetX, event.offsetY))
      //          addEntity(this.diagram.schemat, new Entity(null, diagramPoint.x, diagramPoint.y, 32, 32, "Rename Me"))
      //var newId = (this.pendingEntity==null) ? null : this.pendingEntity.id;  


      var schematChange: SchematChange;

      if (this.pendingAddEntity) {
        var entityViewChange: EntityViewChange = { id: null, _a: ChangeAction.Insert, x: diagramPoint.x, y: diagramPoint.y, width: 32, height: 32 }

        if (this.pendingEntityImport != null) {
          entityViewChange.entity = this.pendingEntityImport.id;
          schematChange = {  id: this.schemat.id, _a: ChangeAction.None, entityViews: [entityViewChange] }
        }
        else {
          var entityChange: EntityChange = {  id: newid(), _a: ChangeAction.Insert, name: "Rename Me" }
          entityViewChange.entity = entityChange.id;
          schematChange = {  id: this.schemat.id, _a: ChangeAction.None, entityViews: [entityViewChange], entities: [entityChange] }
        }
      } else if (this.pendingAddRole) {
        var roleChange: RoleChange = { id: newid(), _a: ChangeAction.Insert, name: "Rename Me" }
        var roleViewChange: RoleViewChange = {
          id: newid(), _a: ChangeAction.Insert, role: roleChange.id,
          position: {_a: ChangeAction.Insert,x: diagramPoint.x, y: diagramPoint.y, width: 32, height: 32 }
        }
        schematChange = { id: this.schemat.id, _a: ChangeAction.None, roleViews: [roleViewChange], roles: [roleChange] }
      } else if (this.pendingAddPath) {
        var pathChange: PathChange = {  id: newid(), _a: ChangeAction.Insert, name: "Rename Me",
            permissions: {id: newid(), _a: ChangeAction.Insert} }
        var pathViewChange: PathViewChange = {
          id: newid(), _a: ChangeAction.Insert, path: pathChange.id,
          position: {_a: ChangeAction.Insert, x: diagramPoint.x, y: diagramPoint.y, width: 32, height: 32 }
        }
        schematChange = {  id: this.schemat.id, _a: ChangeAction.None, pathViews: [pathViewChange], paths: [pathChange] }
      }

      this.schematService.change(schematChange, this.schemat.id)
      this.defaultCursor()
      this.refreshDisplay()

    }

    var diagramPoint = this.diagram.translateToDiagramCoordinates(new Point((event as MouseEvent).offsetX, (event as MouseEvent).offsetY))

    if (this.pendingAddConnection && this.connectedEntityView1 != null) {
      console.log("onMouseUp this.pendingAddConnection && this.connectedEntityView1!=null",
        this.pendingAddConnection, this.connectedEntityView1);
      if (this.connectionGhost != null) {
        this.diagram.shapes = this.diagram.shapes.filter((s) => s != this.connectionGhost)
      }
      const entityView = this.getEntityViewByPoint(diagramPoint)
      if (entityView != null) {
        const change: SchematChange = {
          id: this.id, _a: ChangeAction.Update, connections:
            [{
              id: null, _a: ChangeAction.Update, name: "connect", entity1: this.connectedEntityView1.entity.id, entity2: entityView.entity.id,
              connectionType: ConnectionType.Reference, cardinality: Cardinality.OneToOne
            }]
        }
        this.schematService.change(change)
      }

      this.pendingAddConnection = false
      this.connectedEntityView1 = null
      this.connectionGhost = null
      this.defaultCursor()
      this.refreshDisplay()

    }

    if (this.draggedEntity != null) {
      const entityViewChange: EntityViewChange = {
        id: this.draggedEntity.id, _a: ChangeAction.Update,
        x: diagramPoint.x - this.draggedEntityClickPointOffset.x,
        y: diagramPoint.y - this.draggedEntityClickPointOffset.y
      }

      const change: SchematChange = { id: this.id, _a: ChangeAction.Update, entityViews: [entityViewChange] }
      this.schematService.change(change)
    }
    else if (this.draggedRole != null) {
      const roleViewChange: RoleViewChange = {
        id: this.draggedRole.id, _a: ChangeAction.Update,
        position: {
          _a: ChangeAction.Update,
          x: diagramPoint.x - this.draggedRoleClickPointOffset.x,
          y: diagramPoint.y - this.draggedRoleClickPointOffset.y,
          width: this.draggedRole.position.width,
          height: this.draggedRole.position.width
        }
      }
      const change: SchematChange = { id: this.id, _a: ChangeAction.Update, roleViews: [roleViewChange] }
      this.schematService.change(change)
    }
    else if (this.draggedPath != null) {
      const pathViewChange: PathViewChange = {
        id: this.draggedPath.id, _a: ChangeAction.Update,
        position: {
          _a: ChangeAction.Update,
          x: diagramPoint.x - this.draggedPathClickPointOffset.x,
          y: diagramPoint.y - this.draggedPathClickPointOffset.y,
          width: this.draggedPath.position.width,
          height: this.draggedPath.position.width
        }
      }
      const change: SchematChange = { id: this.id, _a: ChangeAction.Update, pathViews: [pathViewChange] }
      this.schematService.change(change)
    }


    this.pendingAddEntity = false
    this.draggedEntity = null
    this.pendingAddRole = false
    this.draggedRole = null
    this.pendingAddPath = false
    this.draggedPath = null


    // capture the toolbar location
    const toolbarRect = this.toolbarElement.nativeElement.getBoundingClientRect()
    if (this.schemat.toolbarTopLeft.x != toolbarRect.x || this.schemat.toolbarTopLeft.y != toolbarRect.y) {
      let change = this.schematService.createSchematChange()

      change.toolbarTopLeft = new Point(Math.max(toolbarRect.x, 0), Math.max(toolbarRect.y,0))
      change.id = this.schemat.id
      this.schematService.change(change)
    }

    return true;
  }


  highlighted = {}


  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    var changed = true
    var diagramPoint = this.diagram.translateToDiagramCoordinates(new Point(event.offsetX, event.offsetY))
    this.schemat.entityViews.forEach((e) => {
      var s = asEntityShape(e);
      const isIn: boolean = s.isIn(diagramPoint);
      if (s.highlighted != isIn) {
        changed = true
        s.highlight(isIn)
      }
    })
    this.schemat.roleViews.forEach((e) => {
      var s = asRoleShape(e);
      const isIn: boolean = s.isIn(diagramPoint);
      if (s.highlighted != isIn) {
        changed = true
        s.highlight(isIn)
      }
    })
    this.schemat.pathViews.forEach((e) => {
      var s = asPathShape(e);
      const isIn: boolean = s.isIn(diagramPoint);
      if (s.highlighted != isIn) {
        changed = true
        s.highlight(isIn)
      }
    })
    this.diagram.connectionShapes.forEach((cs) => {
      //var s = asConnectionShape(e)
      const isInResult = cs.isIn(diagramPoint);
      if (cs.highlighted != isInResult) {
        changed = true
        cs.highlight(isInResult)
      }
    })


    if (this.draggedEntity != null) {
      this.draggedEntity.x = diagramPoint.x - this.draggedEntityClickPointOffset.x
      this.draggedEntity.y = diagramPoint.y - this.draggedEntityClickPointOffset.y
      changed = true
    } else if (this.draggedRole != null) {
      this.draggedRole.position.x = diagramPoint.x - this.draggedRoleClickPointOffset.x
      this.draggedRole.position.y = diagramPoint.y - this.draggedRoleClickPointOffset.y
      changed = true
    } else if (this.draggedPath != null) {
      this.draggedPath.position.x = diagramPoint.x - this.draggedPathClickPointOffset.x
      this.draggedPath.position.y = diagramPoint.y - this.draggedPathClickPointOffset.y
      changed = true
    }

    else if (this.connectionGhost != null) {
      this.connectionGhost.p2 = diagramPoint
      changed = true
    }
    if (changed) this.refreshDisplay()

  }

  navigateEntity(entity: Entity) {
    this.router.navigate(['entity', this.id, entity.id])
  }

  navigatePath(path: Path) {
    this.router.navigate(['path', this.id, path.id])
  }

  navigateRole(role: Role) {
    this.router.navigate(['role', this.id, role.id])
  }

  navigateConnection(connection: Connection) {
    this.router.navigate(['connection', this.id, connection.id])
  }

  onEntityViewMouseDown(e: EntityView, diagramPoint: Point) {
    if (this.pendingAddConnection) {
      this.connectedEntityView1 = e

      // add connection ghost !!!
      var s = asEntityShape(e)
      var p1 = s.centre()
      var p2 = s.centre()
      this.connectionGhost = new Line(p1, p2, 2)
      this.diagram.shapes.push(this.connectionGhost)
    } else {
      this.draggedEntity = e;
      this.draggedEntityClickPointOffset = new Point(diagramPoint.x - e.x, diagramPoint.y - e.y)
      //      const change: SchematChange = {id: this.id, entities:  [entityChange]  }
      //      this.schematService.change(change)
    }
  }

  onRoleViewMouseDown(e: RoleView, diagramPoint: Point) {
    this.draggedRole = e;
    this.draggedRoleClickPointOffset = new Point(diagramPoint.x - e.position.x, diagramPoint.y - e.position.y)
  }

  onPathViewMouseDown(e: PathView, diagramPoint: Point) {
    this.draggedPath = e;
    this.draggedPathClickPointOffset = new Point(diagramPoint.x - e.position.x, diagramPoint.y - e.position.y)
  }

  getEntityViewByPoint(diagramPoint: Point): EntityView {
    var result = null
    this.schemat.entityViews.forEach(
      (s, _, __) => {
        if (result == null && asEntityShape(s).isIn(diagramPoint)) {
          result = s
        }
      }
    )
    return result
  }

  getRoleViewByPoint(diagramPoint: Point): RoleView {
    var result = null
    this.schemat.roleViews.forEach(
      (s, _, __) => {
        if (result == null && asRoleShape(s).isIn(diagramPoint)) {
          result = s
        }
      }
    )
    return result
  }

  getPathViewByPoint(diagramPoint: Point): PathView {
    var result = null
    this.schemat.pathViews.forEach(
      (s, _, __) => {
        if (result == null && asPathShape(s).isIn(diagramPoint)) {
          result = s
        }
      }
    )
    return result
  }


  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    var diagramPoint = this.diagram.translateToDiagramCoordinates(new Point(event.offsetX, event.offsetY))
    const entityView = this.getEntityViewByPoint(diagramPoint)
    if (entityView != null) {
      this.onEntityViewMouseDown(entityView, diagramPoint)
    } else {
      const roleView = this.getRoleViewByPoint(diagramPoint)
      if (roleView != null) {
        this.onRoleViewMouseDown(roleView, diagramPoint)
      } else {
        const pathView = this.getPathViewByPoint(diagramPoint)
        if (pathView != null ) {
             this.onPathViewMouseDown(pathView, diagramPoint)
        }
      }
    }
  }

  form() {
    console.log("***** form ")
    this.router.navigate(['schemat', this.id])
  }


}
