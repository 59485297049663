import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Schemat, Entity } from 'src/app/schemat';

@Component({
  selector: 'app-import-toolbar',
  templateUrl: './import-toolbar.component.html',
  styleUrls: ['./import-toolbar.component.css']
})
export class ImportToolbarComponent implements OnInit {

  @Input() schemat: Schemat 
  @Input() import: Schemat 

  constructor() { }

  entities() : Entity[] {
    //console.log('this.import', this.import)
    //console.log('this.import.entities', this.import.entities)
    if (this.import.entities) 
       return this.import.entities.filter((e)=>!e.isExternal && !e.isValueType);
    else return []   
  }

  @Output()
  importEntity: EventEmitter<Entity> = new  EventEmitter();
 
  onEntity(e: Entity) {
    console.log("onEntity", e);
   this.importEntity.emit(e);
 }

  ngOnInit() {
  }


  dragRelease(event: Object) {
  }
  
  dropListEnterPredicate(event: Object) {
    return false
  }
  
  drop(event: object) {
  }

}
