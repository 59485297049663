import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SchematService } from 'src/app/service/schemat.service';

import { Schemat, Entity,  Connection, ConnectionChange, SchematChange, ChangeAction } from 'src/app/schemat';
import {  getConnectionById } from 'src/app/schemat-read-util';


import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';



@Component({
  selector: 'app-connection-form',
  templateUrl: './connection-form.component.html',
  styleUrls: ['./connection-form.component.css']
})
export class ConnectionFormComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router,
    private schematService: SchematService,
    private formBuilder: FormBuilder
    ) { 
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.connectionId = params['connectionId'];
      this.schemat = this.schematService.getById(this.id) 
      this.connection = getConnectionById(this.schemat, this.connectionId)
      //this.loadEntity()
    });
    this.createForm();
    this.setChangeValidate()
  }

  id: String
  connectionId: String

  schemat : Schemat
  connection: Connection

  formGroup: FormGroup;
  titleAlert: string = 'This field is required';

  setChangeValidate() {

   }

  createForm() {
     this.formGroup = this.formBuilder.group({
       'name': [this.connection.name, Validators.required],
       'connectionType': [this.connection.connectionType, Validators.required],
       'cardinality': [this.connection.cardinality, Validators.required],
       'minTo': [this.connection.minTo, Validators.required],
       'targetEntity' : [this.connection.entity2, Validators.required]
     });
   }
 
allReferencedEntities(schemat: Schemat) : Entity[]{
   // TODO load nested imprts correctly !
   var result = schemat.entities ? schemat.entities : []
   if (schemat.imports) {
     schemat.imports.forEach((imp)=> {
         result = result.concat(this.allReferencedEntities(imp))
     })
   } 
   return result
}

entities() : Entity[]{
  return this.allReferencedEntities(this.schemat)
}

   get name() {
    return this.formGroup.get('name') as FormControl
  }

  get connectionType() {
    return this.formGroup.get('connectionType') as FormControl
  }

  get cardinality() {
    return this.formGroup.get('cardinality') as FormControl
  }

  get targetEntity() {
    return this.formGroup.get('targetEntity') as FormControl
  }

  get minTo() {
    return this.formGroup.get('minTo') as FormControl
  }

   apply() {
     const newName: String = this.name.value
     const newCardinality = this.cardinality.value
     const newConnectionType = this.connectionType.value
     const newTargetEntity = this.targetEntity.value
     const newMinTo = this.minTo.value
     const change : ConnectionChange = {id: this.connection.id, _a:ChangeAction.Update,   "name": newName,
        connectionType: newConnectionType, cardinality: newCardinality,
        entity1:this.connection.entity1.id, entity2:newTargetEntity.id,
         minTo: newMinTo}
     const scopedChange : SchematChange = {id: this.id, _a:ChangeAction.Update,  connections: [change]}
     this.schematService.change(scopedChange)
    }

   delete() {
    const connectionId = this.connection.id;
    const entityId = this.connection.entity1.id;
    const change : ConnectionChange = {id: connectionId,  _a:ChangeAction.Delete}
    const scopedChange : SchematChange = {id: this.id, _a:ChangeAction.Update, connections: [change]}        
    this.schematService.change(scopedChange);
    this.router.navigate(['entity', this.id, entityId ])


  }

  navigateEntity1() {
    this.router.navigate(['entity', this.id, this.connection.entity1.id ])
  }

  navigateEntity2() {
    this.router.navigate(['entity', this.id, this.connection.entity2.id ])
  }

  cancel() {
    this.navigateEntity1()
  }

  save() {
    this.apply()
    this.navigateEntity1()
  }




}
