import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { RouterModule} from "@angular/router";


import { AppComponent } from './app.component';
import { AuthService} from './auth.service'
import { AllschematsComponent } from './edit/allschemats/allschemats.component';
import { WelcomeComponent } from './welcome/welcome.component';


/* Amplify */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import Amplify from 'aws-amplify';

import { DiagramComponent } from './edit/diagram/diagram.component';
import { EditorComponent } from './edit/editor/editor.component';

import { MaterialModule } from './material.module';
import { ToolbarComponent } from './edit/toolbar/toolbar.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { SchematService } from './service/schemat.service';
import { EntityFormComponent } from './edit/entity-form/entity-form.component';
import { ConnectionFormComponent } from './edit/connection-form/connection-form.component';

import {getAmplifyConfiguration} from './amplifyConfiguration';
import { SchematFormComponent } from './edit/schemat-form/schemat-form.component';
import { ImportFormComponent } from './edit/import-form/import-form.component';
import { ImportToolbarComponent } from './edit/import-toolbar/import-toolbar.component';
import { PathFormComponent } from './edit/path-form/path-form.component';
import { RoleFormComponent } from './edit/role-form/role-form.component';
import { PathSegmentFormComponent } from './edit/path-segment-form/path-segment-form.component'



/* Configure Amplify resources */
//https://docs.amplify.aws/lib/auth/start/q/platform/js#configure-your-application
Amplify.configure(getAmplifyConfiguration());

@NgModule({
  declarations: [
    AppComponent,
    AllschematsComponent,
    WelcomeComponent,
    DiagramComponent,
    EditorComponent,
    ToolbarComponent,
    EntityFormComponent,
    ConnectionFormComponent,
    SchematFormComponent,
    ImportFormComponent,
    ImportToolbarComponent,
    PathFormComponent,
    RoleFormComponent,
    PathSegmentFormComponent
  ],
  imports: [
    AmplifyUIAngularModule, BrowserModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
      { path: 'welcome', component: WelcomeComponent },
      { path: 'all', component: AllschematsComponent },
      { path: 'editor/:id', component: EditorComponent  },
      { path: 'schemat/:id', component: SchematFormComponent  },
      { path: 'import/:id/:index', component: ImportFormComponent  },
      { path: 'entity/:id/:entityId', component: EntityFormComponent  },
      { path: 'connection/:id/:connectionId', component: ConnectionFormComponent  },
      { path: 'role/:id/:roleId', component: RoleFormComponent  },
      { path: 'path/:id/:pathId', component: PathFormComponent  },
      { path: 'pathsegment/:id/:pathId/:pathSegmentId', component: PathSegmentFormComponent  }
    ])

  ],
  entryComponents: [
    //EntityDialogComponent, PropertyDialogComponent
  ],
  providers: [AuthService, SchematService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(_auth: AuthService) {
  }
 }
