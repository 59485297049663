import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SchematService } from 'src/app/service/schemat.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Schemat, SchematChange, ChangeAction } from 'src/app/schemat';

@Component({
  selector: 'app-import-form',
  templateUrl: './import-form.component.html',
  styleUrls: ['./import-form.component.css']
})
export class ImportFormComponent implements OnInit {

  id: String;
  schemat: Schemat;
  index: number;
  currentImport: Schemat;
  formGroup: FormGroup;
  importOptions: Schemat[];

  constructor(private route: ActivatedRoute,
    private router: Router,
    private schematService: SchematService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.index = parseInt(params['index']);
      this.schemat = this.schematService.getById(this.id);   
      this.currentImport = this.schemat.imports[this.index]; 
      console.log("import ", this.currentImport)
      this.createForm();

    });
    this.schematService.retrieveByLoggedInUser().then(
         (schemats) => {
            this.importOptions =schemats.filter((s)=>
               { return s.childId!=null} ) as Schemat[];
         }
    );
    //this.createForm();
  }

  get importId() {
    return this.formGroup.get('importId') as FormControl
  }

 createForm() {
    this.formGroup = this.formBuilder.group({
      'importId' : [this.currentImport.id, Validators.required]

    });
  }

  apply() : String {
    const importId = this.formGroup.value['importId']
    var newImportIds = this.schemat.imports.map( (s) => {
      return s.id
     } );
    newImportIds.splice(this.index, 1, importId) 
    const change: SchematChange = { id: this.id,  _a:ChangeAction.Update,  imports: newImportIds  }
    console.log("change import ", change);
    this.schematService.change(change) 
    return importId;
   // this.schematService.getById(importId)

  }

  back() {
    this.router.navigate(['editor', this.id ])
  }

  save() {
    const id = this.apply();
    this.schematService.retrieveById(id).then(
      (importedSchemat) => {
             this.schemat.imports[this.index] = importedSchemat;
             this.back();
             console.log("loaded import: ", importedSchemat);
            }
    );
  }

  cancel() {
     this.back()
  }


}
