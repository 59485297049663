import { Schemat, Entity, Role, Path, PathSegment, EntityView, PathView, Connection, RoleView, ConnectionType } from './schemat'

export function getEntityById(s: Schemat, entityId: String): Entity | null {
    if (entityId == null || s.entities == null) {
        return null
    }
    var result: Entity | null = null 
    s.entities.forEach((e) => {
        if (result == null && entityId == e.id) {
            result = e
        }
    })
    return result
}

export function getRoleById(s: Schemat, roleId: String): Role | null {
    if (roleId == null || s.roles == null) {
        return null
    }
    var result: Role | null = null
    s.roles.forEach((e) => {
        if (result == null && roleId == e.id) {
            result = e
        }
    })
    return result
}

export function getPathById(s: Schemat, pathId: String): Path | null {
    if (pathId == null || s.roles == null) {
        return null
    }
    var result: Path | null = null
    s.paths.forEach((e) => {
        if (result == null && pathId == e.id) {
            result = e
        }
    })
    return result
}

export function getRouteToPathSegmentId(path: Path, pathSegmentId: String): PathSegment[] | null {
    const currentRoute: PathSegment[] = []
    for (var i = 0; i < path.segments.length; i++) {
        const segment = path.segments[i]
        /*if (segment.id==pathSegmentId) {
            return [segment]
        } */
        const route = getPathSegmentRouteByPathSegmentId(currentRoute, segment, pathSegmentId)
        if (route != null) {
            return route
        }
    }
    return null
}

function getPathSegmentRouteByPathSegmentId(currentRoute: PathSegment[], segment: PathSegment, pathSegmentId: String): PathSegment[] | null {
    if (pathSegmentId == segment.id) {
        currentRoute.push(segment)
        return currentRoute
    }

    if (!segment.segments) {
        return null
    }
    for (var i = 0; i < segment.segments.length; i++) {
        const subSegment = segment.segments[i]
        /*if (subSegment.id==pathSegmentId) {
            currentRoute.push(subSegment)
            return currentRoute
        } */
        currentRoute.push(segment)
        const route = getPathSegmentRouteByPathSegmentId(currentRoute, subSegment, pathSegmentId)
        if (route != null) {
            return route
        }
        currentRoute.pop()
    }
    return null
}

export function getAllPathSegments(pathSegmentsIn: PathSegment[], allPathSegments: PathSegment[]): PathSegment[] | null {
    if (!pathSegmentsIn) {
      return null
    }
    for (var i = 0; i < pathSegmentsIn.length; i++) {
      const childSegment = pathSegmentsIn[i]
      allPathSegments.push(childSegment)
      getAllPathSegments(childSegment.segments, allPathSegments)
    }
    return allPathSegments
  }

export function getEntityViewById(s: Schemat, entityViewId: String): EntityView | null {
    if (entityViewId == null) {
        return null
    }
    var result: EntityView | null = null
    s.entityViews.forEach((e) => {
        if (result == null && entityViewId == e.id) {
            result = e
        }
    })
    return result
}


export function getEntityByIdIncludeImports(s: Schemat, entityId: String): Entity | null {
    var result = getEntityById(s, entityId)
    if (!result) result = findEntityImportById(s, entityId)
    return result
}

export function isLocal(s: Schemat, entityId: String): boolean {
    var result = getEntityById(s, entityId)
    return result !=null
}


export function getRoleViewById(s: Schemat, roleViewId: String): RoleView | null   {
    if (roleViewId == null) {
        return null
    }
    var result: RoleView | null = null
    s.roleViews.forEach((e) => {
        if (result == null && roleViewId == e.id) {
            result = e
        }
    })
    return result
}

export function getPathViewById(s: Schemat, pathViewId: String): PathView | null{
    if (pathViewId == null) {
        return null
    }
    var result: PathView | null = null
    s.pathViews.forEach((e) => {
        if (result == null && pathViewId == e.id) {
            result = e
        }
    })
    return result
}

export function findEntityImportById(schemat: Schemat, entityId: String) {
    if (entityId == null) {
        return null;
    }
    if (schemat.imports == null) {
        return null;
    }
    for (var i = 0; i < schemat.imports.length; i++) {
        const ef = getEntityById(schemat.imports[i], entityId);
        if (ef != null) {
            return ef;
        }
    }
    return null;
}

export function getConnectionByIdSearchimports(s: Schemat, connectionId: String): Connection | null {
    const allSchemas = [s].concat(s.imports)
    for (var i = 0; i < allSchemas.length; i++) {
        const connection = getConnectionById(allSchemas[i], connectionId)
        if (connection != null) {
            return connection
        }
    }
    return null
}

export function getLinkedConnectionsSearchImports(s: Schemat, entity: Entity): Connection[]{
    const allSchemas = [s].concat(s.imports)
    var linkedConnections:Connection[]=[]
    for (var i = 0; i < allSchemas.length; i++) {
        linkedConnections = linkedConnections.concat ( allSchemas[i].connections.filter(c=> {return c.entity1==entity || c.entity2==entity}) )
    }
    return linkedConnections
}

export function getConnectionById(s: Schemat, connectionId: String): Connection | null {
    var result: Connection | null  = null
    if (s.connections) {
        s.connections.forEach((c) => {
            if (result == null && connectionId == c.id) {
                result = c
            }
        })
    }
    return result
}

export class SchematSummary {
    constructor(public id: String, public name: String,
        public childId?: String, public parentId?: String, public version?: String
    ) {
    }
}

export function isRoot(schemat: Schemat, entity: Entity) : Boolean{
    if (entity.isExternal) return false
    const connections = schemat.connections
    for (var i =0; i<connections.length; i++) {
      const connection = connections[i] 
      if (connection.entity2===entity && connection.connectionType===ConnectionType.Contains) {
          return false
       }
    } 
    return true
}

export function connectionsFrom(schemat: Schemat, entity: Entity) : Connection[] {
    var result:Connection[] = [];
    if (schemat.imports) {
    schemat.imports.forEach(
        (imp) => {
             const importedConnections = connectionsFrom(imp, entity);
             result = result.concat(importedConnections);
            }
    ); 
    }
    result = result.concat(schemat.connections.filter( c => c.entity1===entity ));
    return result;
}
