
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SchematService } from 'src/app/service/schemat.service';

import {
  Schemat, Entity, SchematChange,
  ChangeAction, Path, PathViewChange, PathSegmentChange, PathSegment, PathChange, Connection, Permissions
} from 'src/app/schemat';

import { newid } from '../../schemat-util';
import { getPathById } from '../../schemat-read-util';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { timeStamp } from 'console';


export class SegmentSummary {
  constructor(public level: number, public description: String, public segment: PathSegment) {
  }
}

export class SegmentHolder {
  constructor(public level: number, public description: String, public segment: PathSegment, public childSegments: SegmentHolder[], public included: boolean) {
  }
}

export function flattenAsSegmentSummaries(segments: PathSegment[], level: number, summaries: SegmentSummary[]) {
  if (!segments) return summaries
  segments.forEach(
    (s) => {
      const p = s.permissions
      const accessRights = `${p.deny ? "deny " : ""}${p.read ? "read " : ""}${p.write ? "write " : ""}`

      const description = `${s.allProperties ? "*" : " "} ${s.connection != null && !s.allProperties ? s.connection.name : ""} [ ${accessRights}]`
      const summary = new SegmentSummary(level, description, s)
      summaries.push(summary)
      flattenAsSegmentSummaries(s.segments, level + 1, summaries)
    }
  )
  return summaries
}

function allReferencedConnections(schemat: Schemat, fromEntity: Entity): Connection[] {
  var result = schemat.connections ? schemat.connections.filter((c) => { return c.entity1 == fromEntity }) : []
  if (schemat.imports) {
    schemat.imports.forEach((imp) => { result = result.concat(allReferencedConnections(imp, fromEntity)) }
    )
  }
  return result

}



export function flattenAsSegmentSummaries2(schemat: Schemat, root: Entity, existingSegmentHolders: SegmentHolder[], existingSegments: PathSegment[],
  level: number, allSegmentHolders: SegmentHolder[]) {
  // if (!segments) return summaries

  var connections = allReferencedConnections(schemat, root)
  //connections = [connections[0]]
  connections.forEach(
    (c) => {
      //const matchingSegs = existingSegments.filter((s)=> {return c==s.connection } )
      //const included = matchingSegs.length>0
      //const s = included?matchingSegs[0]:new PathSegment(newid(), c, false, new Permissions(newid(), false, false, false))
      const matchingSegHolders = existingSegmentHolders.filter((sh) => { return c == sh.segment.connection })
      if (matchingSegHolders.length > 1) {
        throw Error(`matched too many segmentHolders ${matchingSegHolders.length} to connection ${root.name}.${c.name}`)
      }
      var segment: PathSegment = null

      //new PathSegment(newid(), c, false, new Permissions(newid(), false, false, true))
      var segHolder: SegmentHolder
      if (matchingSegHolders.length == 0) {
        // is there a existing matcing segment ?
        const matchingExistingSegments = existingSegments.filter((s) => { return c == s.connection })
        var segment: PathSegment
        var included: boolean
        if (matchingExistingSegments.length > 0) {
          segment = matchingExistingSegments[0]
          included = true
        } else {
          segment = new PathSegment("", c, false, new Permissions("", false, false, false))
          included = false
        }
        segHolder = new SegmentHolder(level, `seg:${c.name}`, segment, [], included)
        existingSegmentHolders.push(segHolder)
      } else {
        segHolder = matchingSegHolders[0]
      }
      allSegmentHolders.push(segHolder)
      if (segHolder.included && !c.entity2.isValueType) {
        flattenAsSegmentSummaries2(schemat, c.entity2, segHolder.childSegments, segHolder.segment.segments, level + 1, allSegmentHolders)
      }

      //const p = s.permissions
      //const accessRights=`${p.deny?"deny ":""}${p.read?"read ":""}${p.write?"write ":""}`
      //const description = `${s.allProperties ? "*" : " "} ${s.connection != null && !s.allProperties ? s.connection.name : ""} [ ${accessRights}]`

    }
  )
}


@Component({
  selector: 'app-path-form',
  templateUrl: './path-form.component.html',
  styleUrls: ['./path-form.component.css']
})
export class PathFormComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router,
    private schematService: SchematService,
    private formBuilder: FormBuilder
  ) {
  }

  id: String
  pathId: String

  schemat: Schemat
  path: Path

  formGroup: FormGroup;
  titleAlert: string = 'This field is required';



  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.pathId = params['pathId'];
      this.schemat = this.schematService.getById(this.id)
      this.path = getPathById(this.schemat, this.pathId)
      //this.loadEntity()
    });
    this.createForm();
    this.setChangeValidate()
  }


  setChangeValidate() {
  }

  createForm() {
    const p = this.path.permissions
    this.formGroup = this.formBuilder.group({
      'name': [this.path.name, Validators.required],
      'root': [this.path.root, Validators.nullValidator],
      'entityDefault': [this.path.entityDefault, Validators.nullValidator],
      'read': [p.read, Validators.nullValidator],
      'write': [p.write, Validators.nullValidator],
      'deny': [p.deny, Validators.nullValidator],
    });
  }

  get name() {
    return this.formGroup.get('name') as FormControl
  }

  get root() {
    return this.formGroup.get('root') as FormControl
  }

  get allProperties() {
    return this.formGroup.get('allProperties') as FormControl
  }

  get read() {
    return this.formGroup.get('read') as FormControl
  }

  get write() {
    return this.formGroup.get('write') as FormControl
  }
  get deny() {
    return this.formGroup.get('deny') as FormControl
  }

  allReferencedEntities(schemat: Schemat): Entity[] {
    // TODO load nested imprts correctly !
    var result = schemat.entities ? schemat.entities.filter((e) => !e.isValueType) : []
    if (schemat.imports)
      schemat.imports.forEach((imp) => {
        result = result.concat(this.allReferencedEntities(imp))
      })
    return result
  }

  entities(): Entity[] {
    return this.allReferencedEntities(this.schemat)
  }

  addPathSegment() {
    console.log("addPathSegment")
    const pathSegmentChange: PathSegmentChange = {
      id: newid(), _a: ChangeAction.Insert, allProperties: true,
      permissions: { id: newid(), _a: ChangeAction.Insert }
    }
    const change: SchematChange = {
      id: this.id, _a: ChangeAction.None,
      paths: [{ id: this.pathId, _a: ChangeAction.None, segments: [pathSegmentChange] }]
    }
    this.schematService.change(change)
  }

  segments(): SegmentSummary[] {
    return flattenAsSegmentSummaries(this.path.segments, 0, [])
    //return this.path.segments.map((s)=> { return new SegmentSummary(1, `${s.allProperties?"All Fields": " "} ${s.connection!=null && !s.allProperties ? s.connection.name: ""}`, s)  } )
  }


  changeIncluded(holder: SegmentHolder, event: any) {
    console.log("holder:", holder)
    holder.included = !holder.included
    console.log("changeIncludedClick", holder, event, holder)
  }

  changeIncludedClick(holder: SegmentHolder, event: any) {
    console.log("changeIncludedClick", holder, event, holder)
  }

  toggleDeny(holder: SegmentHolder, event: any) {
    console.log("changeIncludedClick", holder, event, holder)
    holder.segment.permissions.deny = !holder.segment.permissions.deny
  }

  isDeny(holder: SegmentHolder): boolean {
    console.log("isDeny returning " + holder.segment.permissions.deny)
    return holder.segment.permissions.deny
  }

  getfalse(): boolean {
    return false
  }

  gettrue(): boolean {
    return true
  }

  toggleDenyClick(holder: SegmentHolder, event: any) {
    const existingValue = holder.segment.permissions.deny
    holder.segment.permissions.deny = !existingValue
    console.log(`toggleDenyClick chenged from ${existingValue} to ${!existingValue}  `)

  }
  toggleReadClick(holder: SegmentHolder, event: any) {
    console.log("toggleReadClick", holder, event, holder)
    holder.segment.permissions.read = !holder.segment.permissions.read
  }
  toggleWriteClick(holder: SegmentHolder, event: any) {
    console.log("toggleWriteClick", holder, event, holder)
    holder.segment.permissions.write = !holder.segment.permissions.write
  }
  toggleAllPropertiesClick(holder: SegmentHolder, event: any) {
    console.log("toggleAllPropertiesClick", holder, event, holder)
    holder.segment.allProperties = !holder.segment.allProperties
  }


  //id2Summary: { [index: string]: SegmentHolder } 
  existingSegmentHolders: SegmentHolder[] = []

  nextSegments(): SegmentHolder[] {
    const allSegmentHolder: SegmentHolder[] = []
    if (this.path.root) {
      flattenAsSegmentSummaries2(this.schemat, this.path.root, this.existingSegmentHolders, this.path.segments, 0, allSegmentHolder)
    }
    return allSegmentHolder
    //return this.path.segments.map((s)=> { return new SegmentSummary(1, `${s.allProperties?"All Fields": " "} ${s.connection!=null && !s.allProperties ? s.connection.name: ""}`, s)  } )
  }

  apply() {
    const pathChange: PathChange = {
      id: this.pathId, _a: ChangeAction.Update,
      permissions: { id: this.path.permissions.id, _a: ChangeAction.Update }
    }
    var changed = false

    const newname = this.formGroup.value['name']
    if (this.path.name != newname) {
      changed = true
      pathChange.name = newname
    }
    const newRoot = this.formGroup.value['root'] as Entity
    if (this.path.root != newRoot) {
      changed = true
      pathChange.root = newRoot.id
    }
    const newEntityDefault = this.formGroup.value['entityDefault'] as boolean
    if (this.path.entityDefault != newEntityDefault) {
      changed = true
      pathChange.entityDefault = newEntityDefault
    }
    const p = this.path.permissions
    const newRead = this.formGroup.value['read'] as boolean
    if (p.read != newRead) {
      changed = true
      pathChange.permissions.read = newRead
    }
    const newWrite = this.formGroup.value['write'] as boolean
    if (p.write != newWrite) {
      changed = true
      pathChange.permissions.write = newWrite
    }
    const newDeny = this.formGroup.value['deny'] as boolean
    if (p.deny != newDeny) {
      changed = true
      pathChange.permissions.deny = newDeny
    }
    const change: SchematChange = {
      id: this.id, _a: ChangeAction.None,
      paths: [pathChange]
    }

    const segmentChanges: PathSegmentChange[] = this.getSegmentChanges(this.existingSegmentHolders)
    if (segmentChanges.length > 0) {
      changed = true
      pathChange.segments = segmentChanges
    }

    if (changed) this.schematService.change(change)
    console.log("change, root:", change, this.path)

    this.existingSegmentHolders = []

  }

  getSegmentChanges(segmentHolders: SegmentHolder[]): PathSegmentChange[] {
    const pathSegmentChanges: PathSegmentChange[] = []
    segmentHolders.forEach(
      (sh) => {
        const isNew = !(sh.segment.id) || sh.segment.id.length < 2

        if (!sh.included && !isNew) {
          const action: ChangeAction = ChangeAction.Delete
          const pathSegmentChange: PathSegmentChange = { id: sh.segment.id, _a: action }
          pathSegmentChanges.push(pathSegmentChange)
        }
        else if (sh.included) {
          const action: ChangeAction = isNew?ChangeAction.Insert:ChangeAction.Update
          const id = isNew?newid():sh.segment.id
          const permission = sh.segment.permissions
          const isPermissionNew = !(permission.id) || permission.id.length>1
          const permissionAction: ChangeAction = isPermissionNew?ChangeAction.Insert:ChangeAction.Update
          const permissionId = isPermissionNew?newid():sh.segment.permissions.id

          const pathSegmentChange: PathSegmentChange = {
            id: id, _a: action,
            connection: sh.segment.connection.id,
            permissions: { _a: permissionAction, id: permissionId, deny: permission.deny, read: permission.read, write: permission.write }
          }
          if (sh.childSegments) pathSegmentChange.segments=this.getSegmentChanges(sh.childSegments)
          pathSegmentChanges.push(pathSegmentChange)
        }

      }
    )
    return pathSegmentChanges
  }



  editSegment(segment: PathSegment) {
    console.log(`segment: `, segment);
    // pathsegment/:id/:pathId/:pathSegmentId
    this.router.navigate(['pathsegment', this.id, this.pathId, segment.id])
  }

  deletable(): boolean {
    return true
  }

  delete() {
    const pathViewChanges: PathViewChange[] = this.schemat.pathViews.filter((ev) => { return ev.path === this.path }).map(
      (ev) => { return { id: ev.id, _a: ChangeAction.Delete, _del: true } });
    this.schematService.change({ id: this.schemat.id, _a: ChangeAction.None, pathViews: pathViewChanges });
    this.schematService.change({ id: this.schemat.id, _a: ChangeAction.None, paths: [{ id: this.path.id, _a: ChangeAction.Delete }] });
    this.router.navigate(['editor', this.id])
  }

  back() {
    this.router.navigate(['editor', this.id])
  }

  save() {
    this.apply()
    this.back()
  }

  cancel() {
    this.back()
  }

}



