import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SchematService } from 'src/app/service/schemat.service';

import { Schemat, SchematChange, ChangeAction, Role, RoleViewChange, Entity, Path } from 'src/app/schemat';

import {  getRoleById } from 'src/app/schemat-read-util';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.css']
})
export class RoleFormComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router,
    private schematService: SchematService,
    private formBuilder: FormBuilder
    ) { 
  }

  id: String
  roleId: String

  schemat : Schemat
  role: Role

  formGroup: FormGroup;
  titleAlert: string = 'This field is required';

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.roleId = params['roleId'];
      this.schemat = this.schematService.getById(this.id) 
      this.role = getRoleById(this.schemat, this.roleId)
      //this.loadEntity()
    });
    this.createForm();
    this.setChangeValidate()
  }



  setChangeValidate() {
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'name': [this.role.name, Validators.required],
      'paths': [this.role.paths, Validators.nullValidator]
    });
  }

  allPaths() {
    return this.schemat.paths
  }

  get name() {
        return this.formGroup.get('name') as FormControl
  }

  get paths() {
    return this.formGroup.get('paths') as FormControl
}


 areSame(paths1: Path[], paths2: Path[]) : boolean {
      if (!paths1) paths1 = []
      if (!paths2) paths2 = []
      if (paths1.length!=paths2.length) {
           return false
      }
      for (var i =0; i<paths1.length; i++) {
          if (paths2.filter((p)=> { return paths1[i]==p;} ).length!=1) {
            return false
          }
      }
      return true
}

  apply() {
    const change: SchematChange = { id: this.id, _a:ChangeAction.None,
         roles: [{id: this.roleId, _a:ChangeAction.Update }]  } 
    var changed = false
    const newname = this.formGroup.value['name']
    if (this.role.name != newname) {
        change.roles[0].name=newname
        changed = true
    } 
    const newPaths = this.formGroup.value['paths'] as Path[]
    if (!this.areSame(newPaths, this.role.paths )) {
      change.roles[0].paths = newPaths.map((p)=> { return p.id })
      changed = true
    } 
  
    if (changed) {
      this.schematService.change(change) 
      changed = true
    }

  }

  deletable() : boolean 
     {
       return true
     }

  delete() {
     const roleViewChanges : RoleViewChange[] = this.schemat.roleViews.filter((ev)=> {return ev.role === this.role }).map(
       (ev) => { return { id: ev.id, _a:ChangeAction.Delete, _del:  true   }   });
     this.schematService.change({id: this.schemat.id, _a:ChangeAction.None, roleViews: roleViewChanges  }); 
     this.schematService.change({id: this.schemat.id, _a:ChangeAction.None, roles: [{id: this.role.id, _a:ChangeAction.Delete  }]  }); 
     this.router.navigate(['editor', this.id ])
  }

  back() {
    this.router.navigate(['editor', this.id ])
  }

  save() {
    this.apply()
    this.back() 
  }

  cancel() {
     this.back()
  }

}



