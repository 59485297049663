import { Component, OnInit, NgZone } from '@angular/core';
import {AuthService} from './auth.service';
import { ApplicationRef } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Router} from '@angular/router';


/*
see here
https://docs.amplify.aws/ui/auth/authenticator/q/framework/angular#sign-in
*/

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit  {

  title = 'schemact-editor';

  isLoggedIn = true;
  test = "xxtestxx"
  disableSecurity = false

  user: { id: string; username: string; email: string };

  userString() {
    return this.user==null?"null":`${this.user.id} ${this.user.username} ${this.user.email}`
  }

  constructor(private authService: AuthService, private applicationRef: ApplicationRef,
    private changeDetectorRef: ChangeDetectorRef, private router: Router, private ngZone: NgZone,) {}

  ngOnInit(): void {
    this.authService.isLoggedIn$.subscribe(
      isLoggedInIn =>  {
        this.isLoggedIn = isLoggedInIn;
        if (!this.isLoggedIn && !this.disableSecurity) {
          this.ngZone.run(() => this.router.navigate(['welcome', { }]));
        } else {
          this.ngZone.run(() => this.router.navigate(['all', { }]));
        }

        // set the route
        this.changeDetectorRef.detectChanges();
      }
    );

    this.authService.auth$.subscribe(({ id, username, email }) => {
      this.user = { id, username, email };
      this.changeDetectorRef.detectChanges();
    });
  }

}
