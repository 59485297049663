import { Component, OnInit, HostBinding, ViewChild, ElementRef, Input } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { MatIconRegistry } from '@angular/material/icon'
import { Output } from '@angular/core'
import { EventEmitter } from '@angular/core'
import {  Point }   from  '../../schemat-externals'


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})

export class ToolbarComponent implements OnInit {

  @Input() title: String 

  iconfilenames = ["moveleft", "moveright", "moveup", "movedown", "zoomin", "zoomout", "entity", "connection", "interface", "path", "role" ]

  entityDragPosition = {x: 0, y: 0};

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {

    this.iconfilenames.forEach((name) => {
    this.matIconRegistry.addSvgIcon(
      name,
      domSanitizer.bypassSecurityTrustResourceUrl("assets/" + name + ".svg")
    );
    })

  }

dragRelease(event: Object) {
}

dropListEnterPredicate(event: Object) {
  return false
}

drop(event: object) {
}

@Output()
form = new  EventEmitter<number>();

   @Output()
   zoom = new  EventEmitter<number>();

   onClickZoom(value: number) {
    this.zoom.emit(value);
  }

  @Output()
  move = new  EventEmitter<Point>();
  onClickMove(x: number, y: number) {
   this.move.emit(new Point(x, y));
 }

 @Output()
 entity = new  EventEmitter();

 @Output()
 role = new  EventEmitter();

 @Output()
 path = new  EventEmitter();


 @Output()
 connection = new  EventEmitter();

 onEntity() {
  this.entity.emit();
}

onRole() {
  this.role.emit();
}

onPath() {
  this.path.emit();
}


onConnection() {
  this.connection.emit();
}

  ngOnInit() {
  }

  onClickSchematForm() {
      this.form.emit()
  }


/** current Y position of the native element. */
@HostBinding('style.top.px') public positionTop: number;

/** current X position of the native element. */
@HostBinding('style.left.px') public positionLeft: number;

}
